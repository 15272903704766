import React, { Component, useEffect } from 'react';
import { Layout /* Affix, Button */ } from 'antd';
import { Analytics } from 'aws-amplify';

// import Sidebar from './Sidebar';
import Header from './Header';
import Footer from './Footer';
import AuthModal from '../components/Auth';
import Profile from '../components/Profile';

type Props = {
  children: Component;
  title: string;
};

const AppLayout = ({ children, title }: Props) => {
  // const [isMobile] = useState(window.innerWidth <= 768);
  // const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    const pageTitle = `APN | ${title || 'Home'}`;
    document.title = pageTitle;
    Analytics.record({ name: pageTitle });
  }, [title]);
  return (
    <Layout style={{ minHeight: '100vh' }}>
      {/* {!isMobile && <Sidebar collapsed={collapsed} />} */}
      <Layout>
        <Header />
        <Layout.Content style={{ height: '90vh', overflow: 'auto' }}>
          <div style={{ minHeight: 500 }}>{children}</div>
          <Footer />
          {/* <Affix style={{ position: 'absolute', top: 650, left: 1050 }} onChange={(affixed) => console.log(affixed)}>
            <Button>120px to affix bottom</Button>
          </Affix> */}
        </Layout.Content>
      </Layout>
      <AuthModal />
      <Profile />
    </Layout>
  );
};

export default AppLayout;
