import moment from 'moment';

import { CCItems, Product, TableRecordType } from '../types/Types';

type RequestedCompany = {
  id: string;
  source: string;
};

const getUniqueCompanies = (lineItems: Product[]) => {
  const reqCompanies: RequestedCompany[] = [];
  lineItems.map((item) => {
    if (reqCompanies.length === 0) reqCompanies.push({ id: item.companyInfo.id, source: 'APN' });
    const lastItem = reqCompanies[reqCompanies.length - 1];
    if (lastItem.id !== item.companyInfo.id) reqCompanies.push({ id: item.companyInfo.id, source: 'APN' });
  });
  return reqCompanies;
};

export const getSRFQPayload = ({ lineItems, values }: any) => {
  const {
    expiryDate,
    leadTime = 1,
    comments = '',
    priority = undefined,
    deliveryRequiredBy = undefined,
    rfqTrackingNumber = undefined,
  } = values;
  return {
    input: {
      expiryDate,
      leadTime,
      rfqSource: 'APN_MARKET_PLACE',
      priority,
      deliveryRequiredBy: moment(deliveryRequiredBy).format('YYYY-MM-DD'),
      rfqTrackingNumber,
      requestedCompanies: getUniqueCompanies(lineItems),
      description: comments,
      requestedParts: lineItems.map((item: Product) => item.partNumber),
      lineItems: lineItems.map(({ partNumber, quantity, description, bin, uom, comments, CC }: Product) => ({
        partNumber,
        requestedQty: quantity,
        conditionCode: CC,
        description,
        bin,
        uom,
        comments,
      })),
    },
  };
};

export const splitName = (name = '') => {
  return name.split(' ').slice(0).join(' ');
};

export const convertQtyAndCCIntoRows = (record: TableRecordType) => {
  const ccs = JSON.parse(record.qtyAndPriceByCC || '{}');
  const rows: Array<CCItems> = [];
  Object.keys(ccs || []).forEach((cc) => {
    rows.push({
      cc: cc,
      totalQuantity: ccs[cc].qty,
      listPrice: {
        amount: ccs[cc].listPrice,
        currencyCode: ccs[cc].priceInfo ? ccs[cc].priceInfo.currencyCode : 'USD',
        multiplier: ccs[cc].priceInfo ? ccs[cc].priceInfo.multiplier : 100,
      },
    });
  });
  return rows;
};
