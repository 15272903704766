import { takeLatest, put, select, call } from 'redux-saga/effects';

import { ISagaAction } from '../Types';
import { SEARCH_DATA, searchDataComplete, setSearchLoading } from './Actions';
import { ISearchPayload } from './Types';
import { ISearchProcedure, SearchResult } from '../../common/libs/procedures/SearchProcedure/Types';

function* fetchSearchData({ payload }: ISagaAction<ISearchPayload>) {
  const { context, parameters } = payload;
  yield put(setSearchLoading({ context }));
  const procedure: ISearchProcedure = yield select((state) => state.Search[context].procedure);
  const searchedResults: SearchResult[] = yield call(() => procedure.search(parameters));
  yield put(
    searchDataComplete({
      context,
      searchedResults,
    }),
  );
}

export default function* searchSagas() {
  yield takeLatest(SEARCH_DATA, fetchSearchData);
}
