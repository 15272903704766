import React, { CSSProperties } from 'react';
import { Layout, Row, Col, Button } from 'antd';

import styles from './style.module.scss';
import { apnLogo } from '../../assets';
import history from '../../common/utils/history';

const { Header } = Layout;

export type HeaderItems = {
  label: string;
  path: string;
  style?: CSSProperties;
};

const HEADER_LINKS: HeaderItems[] = [
  {
    label: 'SEARCH',
    path: '/search',
  },
  {
    label: 'REPAIRS',
    path: '/cart',
  },
  // {
  //   label: 'LOGIN',
  //   path: '/login',
  //   style: {
  //     backgroundColor: '#EA5033',
  //     color: 'white',
  //   },
  // },
];

const StaticHeader = () => {
  return (
    <Header className={styles.container}>
      <Row>
        <Col span={6}>
          <img alt="APNSync" src={apnLogo} className={styles.logo} />
        </Col>
        <Col span={18} className={styles.headerActions}>
          {HEADER_LINKS.map(({ label, path, style }, index) => (
            <Button
              key={`static-header-item-${index}`}
              onClick={() => history.push(path)}
              className={styles.headerButton}
              style={style}
            >
              {label}
            </Button>
          ))}
        </Col>
      </Row>
    </Header>
  );
};

export default StaticHeader;
