import { InitSearchPayload, ISearchPayload } from './Types';
import { SearchResult } from '../../common/libs/procedures/SearchProcedure/Types';

export const INIT_SEARCH = 'search/INIT-SEARCH';
export const SEARCH_DATA = 'search/SEARCH-DATA';
export const SEARCH_DATA_LOADING = 'search/SEARCH-DATA-LOADING';
export const SEARCH_DATA_COMPLETE = 'search/SEARCH-DATA-COMPLETE';

export const initSearch = (payload: InitSearchPayload) => {
  return {
    type: INIT_SEARCH,
    payload,
  };
};

export const setSearchLoading = (payload: { context: string }) => ({ type: SEARCH_DATA_LOADING, payload });
export const searchData = (payload: ISearchPayload) => ({ type: SEARCH_DATA, payload });
export const searchDataComplete = (payload: { context: string; searchedResults: SearchResult[] }) => ({
  type: SEARCH_DATA_COMPLETE,
  payload,
});
