import React from 'react';

import styles from './styles.module.scss';
import { videoPlaceHolder } from '../../assets';

type Props = {
  title?: string;
  youtubeId: string;
};

const VideoCard = ({ youtubeId }: Props) => (
  <div className={styles.video}>
    <a
      target="blank"
      href={`https://www.youtube.com/embed/${youtubeId}?autoplay=1&controls=0&showinfo=0&color=white&rel=0`}
      className="featured-video"
    >
      <figure>
        <img alt="play!" src={videoPlaceHolder} />
      </figure>
    </a>
  </div>
);

export default VideoCard;
