import {
  IDataTableProcedure,
  IDataTableResults,
  IDTParameters,
} from '../../common/libs/procedures/DataTableProcedure/Types';

export const INIT_DATA_TABLE = 'data-table/INIT-DATA-TABLE';
export const DATA_TABLE_LOADING = 'data-table/DATA-TABLE-LOADING';
export const DATA_TABLE_LOADING_COMPLETE = 'data-table/DATA_TABLE_LOADING_COMPLETE';
export const FETCH_DATA = 'data-table/FETCH-DATA';
export const FETCH_DATA_COMPLETE = 'data-table/FETCH-DATA-COMPLETE';
export const SET_DATA_TABLE_FILTERS = 'data-table/SET-DATA-TABLE-FILTERS';
export const UPDATE_FILTER_PARAMS = 'data-table/UPDATE-FILTER-PARAMS';

export const initDataTable = (payload: {
  context: string;
  parameters: IDTParameters;
  procedure: IDataTableProcedure;
}) => {
  return {
    type: INIT_DATA_TABLE,
    payload,
  };
};

export const fetchData = (payload: { context: string }) => {
  return {
    type: FETCH_DATA,
    payload,
  };
};

export const fetchDataComplete = (payload: { context: string; results: IDataTableResults }) => ({
  type: FETCH_DATA_COMPLETE,
  payload,
});

export const setDataTableLoading = (payload: { context: string }) => ({ type: DATA_TABLE_LOADING, payload });
export const stopDataLoading = (payload: { context: string }) => ({ type: DATA_TABLE_LOADING_COMPLETE, payload });

export const setDataTableFilters = (payload: {
  context: string;
  filters: {
    current?: number;
    conditionCodes?: Array<string>;
    queryVariable?: Record<string, any>;
    sortBy?: Record<string, any>;
  };
  sorter?: any;
}) => ({
  type: SET_DATA_TABLE_FILTERS,
  payload,
});

export const updateDataTableFilters = (payload: any) => ({ type: UPDATE_FILTER_PARAMS, payload });
