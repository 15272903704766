import { IRoute } from '../../common/types/Types';
import AppLayout from '../../layouts/AppLayout';
import Cart from './Cart';

export const CartRoute: IRoute = {
  path: '/cart',
  component: Cart,
  layout: AppLayout,
  exact: true,
  title: 'Cart',
};
