// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Button } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';

const styles = {
  container: {
    padding: '22px 34px 15px',
    background: 'white',
  },
  link: {
    fontSize: 15,
  },
  textColor: { color: '#595959' },
  heading: {
    color: '#203d57',
    marginTop: 10,
    fontWeight: 400,
    fontSize: 28,
  },
};

type ButtonProps = {
  loading: boolean;
  type: 'default' | 'link' | 'text' | 'ghost' | 'primary' | 'dashed' | undefined;
  size: SizeType;
  disabled: boolean;
  onClick: () => void;
  iconType: string;
  name: string;
  isVisible: boolean;
};

type LayoutContentHeaderProps = {
  breadcrumbs?: Array<{
    name: string;
    link: boolean;
    path: string;
    webLink?: string;
  }>;
  title?: string;
  rightActions?: Array<ButtonProps>;
};
const PageHeader = ({
  title,
  breadcrumbs = [{ name: 'Home', link: false, path: '/', webLink: 'https://www.aeropartsnow.com' }],
  rightActions = [],
}: LayoutContentHeaderProps) => {
  return (
    <>
      <div style={styles.container}>
        <div style={styles.link}>
          {(breadcrumbs || []).map((item, i) => (
            <span key={item.name}>
              {item.link && (
                <Link style={{ fontWeight: 600 }} to={item.path}>
                  {item.name}
                </Link>
              )}
              {!item.link && !item?.webLink && <span style={styles.textColor}>{item.name}</span>}
              {i + 1 !== breadcrumbs.length && <> / </>}
              {item.webLink && <a href={item.webLink}>{item.name}</a>}
            </span>
          ))}
        </div>

        <Row gutter={24}>
          <Col span={12} style={styles.heading}>
            {title}
          </Col>
          <Col
            span={12}
            style={{
              textAlign: 'right',
              paddingTop: '18px',
            }}
          >
            {(rightActions || []).map((btn, i) => (
              <>
                {btn.isVisible && (
                  <Button
                    loading={btn.loading}
                    type={btn.type}
                    key={i}
                    size={btn.size || 'large'}
                    disabled={btn.disabled}
                    onClick={btn.onClick}
                    icon={btn.iconType}
                  >
                    {btn.name}
                  </Button>
                )}
              </>
            ))}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default PageHeader;
