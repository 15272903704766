import React from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

type EditDeleteProps = {
  handleEdit?(): void;
  handleDelete?(): void;
};

type DeleteProps = {
  handleDelete(): void;
};

type EditProps = {
  handleEdit(): void;
};

const style = { border: '1px solid #212121', padding: 5, borderRadius: '50%' };

export const DeleteIcon = ({ handleDelete }: DeleteProps) => <DeleteOutlined style={style} onClick={handleDelete} />;

export const EditIcon = ({ handleEdit }: EditProps) => <EditOutlined style={style} onClick={handleEdit} />;

export const EditDelete = ({ handleEdit, handleDelete }: EditDeleteProps) => {
  return (
    <div>
      {handleEdit && <EditIcon handleEdit={handleEdit} />}
      {handleDelete && <DeleteIcon handleDelete={handleDelete} />}
    </div>
  );
};
