import { get } from 'lodash';

import { ISearchParameters, ISearchProcedure, SearchResult } from './Types';
import BaseService from '../../../../api/BaseService';

class SearchProcedureClass implements ISearchProcedure {
  search = async (parameters: ISearchParameters): Promise<SearchResult[]> => {
    const { searchString, query, networkId } = parameters;
    const res = await BaseService.public({
      query,
      variables: {
        searchString,
        networkId,
      },
    });
    return get(res, 'data.marketPlaceNetworkPartSuggest') as SearchResult[];
  };
}

export const SearchProcedure = new SearchProcedureClass();
