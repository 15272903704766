import React from 'react';
import { Button, Col, Row } from 'antd';
import { ShopOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import styles from './style.module.scss';
import { apnBanner } from '../../assets';

const GetStartedBanner = () => {
  return (
    <div className={styles.bannerStyle} style={{ backgroundImage: `url(${apnBanner})` }}>
      <Row>
        <Col sm={12} xs={24}>
          ADD YOUR INTEGRATED MARKETPLACES HERE
          <p style={{ fontSize: 12 }}>CONNECT POPULAR THIRD-PARTY MARKETPLACES</p>
        </Col>
        <Col sm={12} xs={24} className={styles.alignVertical}>
          <Link to="/buyers">
            <Button icon={<ShopOutlined />}>Get Started</Button>
          </Link>
        </Col>
      </Row>
    </div>
  );
};

export default GetStartedBanner;
