import GetStartedBannerComp from './GetStartedBanner';
import HeroBannerComp from './HeroBanner';
import BannerComponent from './BannerComponent';
import BannerText from './BannerText';
import WhyWeAreDifferentComp from './WhyWeAreDifferentBanner';
import APNBenefitsComp from './APNBenefits';

export const Banner = BannerComponent;
export const BannerTextData = BannerText;
export const HeroBanner = HeroBannerComp;
export const GetStartedBanner = GetStartedBannerComp;
export const WhyWeAreDifferentBanner = WhyWeAreDifferentComp;
export const APNBenefits = APNBenefitsComp;
