import React from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';

import type { Money } from '../../common/types/Types';
import { formatMoneyV2 } from '../../common/utils/currency';
import { GlobalState } from '../../redux-modules/Global/Types';
import { RootState } from '../../redux-modules/store/rootState';

type Props = {
  money: Money;
  showCurrencyFromCompany?: boolean;
};

const Currency = ({ money, showCurrencyFromCompany = true }: Props) => {
  const globalState: GlobalState = useSelector((state: RootState) => state.Global);
  const {
    localization: { currencyCode },
  } = globalState;

  return (
    <>
      {formatMoneyV2(money, {
        currency: showCurrencyFromCompany ? currencyCode : get(money, 'currencyCode', 'USD'),
      })}
    </>
  );
};

export default Currency;
