import React from 'react';
import { Empty, Layout, Button, Row, Col, Divider } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { HistoryOutlined, LoginOutlined } from '@ant-design/icons';
import { get, capitalize } from 'lodash';

import PageHeader from '../../layouts/Header/PageHeader';
import { searchSupplierRFQs } from '../../queries';
import DataTable from '../../components/DataTable';
import { DTProcedure } from '../../common/libs/procedures/DataTableProcedure/DataTableProcedure';
import { RootState } from '../../redux-modules/store/rootState';
import { openModal } from '../../redux-modules/Global/Actions';
import { AUTH_MODAL_ID, DATE_FORMAT } from '../../common/utils/constants';
import { Account, TableRecordType } from '../../common/types/Types';
import GetStartedBanner from '../../components/Banners/GetStartedBanner';
import styles from './style.module.scss';
import LabelHeader from '../../components/DummyHelpers/LabelHeader';

const parameters = {
  query: searchSupplierRFQs,
  pageSize: 25,
  current: 1,
  dataPath: 'searchSupplierRFQS',
};

const History = () => {
  const isLoggedIn: boolean = useSelector((state: RootState) => state.Auth.loggedIn);
  const dispatch = useDispatch();

  const RenderSource = () => 'APN Network';

  const RenderSuppliers = (record: TableRecordType) => {
    const suppliers = (record || []).map((item: Account) => item.accountName);
    return suppliers.join(', ');
  };

  const RenderRequestedParts = (record: Array<string> = []) =>
    record && record.length > 0 ? record.join(', ') : 'N/A';

  const RenderConditionCode = (record: TableRecordType = []) => {
    const conditions = record.map((item: TableRecordType) => item.conditionCode);
    return conditions.join(',');
  };

  const RenderMobileRow = (record: TableRecordType) => {
    console.log(record, 'record');
    return (
      <Row>
        <div className={styles.serialNumber}>{get(record, 'supplierRfqNumber')}</div>
        <Row style={{ width: '100%' }}>
          <Col span={6}>
            <LabelHeader label="CREATED" />
            <div>{moment(get(record, 'createdAt')).format(DATE_FORMAT)}</div>
          </Col>
          <Col span={6} style={{ textAlign: 'center' }}>
            <LabelHeader label="STATUS" />
            <div>{capitalize(get(record, 'status'))}</div>
          </Col>
          <Col span={6} style={{ textAlign: 'center' }}>
            <LabelHeader label="PART #" />
            <div>{RenderRequestedParts(record.requestedParts || [])}</div>
          </Col>
          <Col span={6} style={{ textAlign: 'center' }}>
            <LabelHeader label="CC" />
            <div>{RenderConditionCode(record.lineItems)}</div>
          </Col>
        </Row>
        <Divider />
      </Row>
    );
  };

  return (
    <>
      <PageHeader title="History" />
      <GetStartedBanner />
      <Layout.Content style={{ padding: 20, margin: 20, backgroundColor: 'white', minHeight: 500 }}>
        {!isLoggedIn && (
          <Empty
            image={<HistoryOutlined style={{ color: '#595959' }} />}
            imageStyle={{
              fontSize: 100,
              height: 140,
            }}
            description={
              <span>
                <h2>Please login to see your history</h2>
              </span>
            }
          >
            <Button icon={<LoginOutlined />} onClick={() => dispatch(openModal(AUTH_MODAL_ID))}>
              Login
            </Button>
          </Empty>
        )}
        {isLoggedIn && (
          <DataTable
            style={{ minHeight: '400px' }}
            parameters={parameters}
            context="History"
            procedure={DTProcedure}
            isMobileResponsive
            renderMobileRow={RenderMobileRow}
            columns={[
              {
                title: 'RFQ #',
                dataIndex: 'supplierRfqNumber',
              },
              {
                title: 'Created',
                dataIndex: 'createdAt',
                render: (date) => moment(date).format('LL'),
              },
              {
                title: 'Status',
                dataIndex: 'status',
              },
              {
                title: 'Source',
                dataIndex: 'rfqSource',
                render: RenderSource,
              },
              {
                title: 'Suppliers',
                dataIndex: 'requestedSuppliers',
                render: RenderSuppliers,
                width: 200,
              },
              {
                title: 'Part #',
                dataIndex: 'requestedParts',
                render: RenderRequestedParts,
                width: 200,
              },
              {
                title: 'CC',
                dataIndex: 'lineItems',
                render: RenderConditionCode,
              },
              {
                title: 'Price',
                dataIndex: 'price',
                render: () => 'RFQ',
              },
            ]}
          />
        )}
      </Layout.Content>
    </>
  );
};

export default History;
