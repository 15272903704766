import { Col } from 'antd';
import React from 'react';

import styles from '../../views/buyers/styles.module.scss';

export const Image = ({ image }: { image: string }) => (
  <Col sm={12} xs={24} style={{ textAlign: 'center' }}>
    <img className={styles.bannerImageStyle} alt="list-place-holder" src={image} />
  </Col>
);
