import { IRoute } from '../../common/types/Types';
import AppLayout from '../../layouts/AppLayout';
import StoreFront from './StoreFront';

export const StoreFrontRoute: IRoute = {
  path: '/storefront',
  component: StoreFront,
  layout: AppLayout,
  exact: true,
  title: 'StoreFront',
};
