import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import { IRoute } from '../types/Types';
import { RootState } from '../../redux-modules/store/rootState';

type RouteWrapper = IRoute;

const AuthenticatedRouteWrapper = ({ layout: Layout, component: Component, title, ...props }: RouteWrapper) => {
  const loggedIn = useSelector(({ Auth }: RootState) => Auth.loggedIn);
  const RouteWrapper = () => (
    <Route
      {...props}
      render={(matchProps) => (
        <Layout title={title}>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
  return loggedIn ? <RouteWrapper /> : <Redirect to="/sign-in" />;
};

export default AuthenticatedRouteWrapper;
