import React from 'react';
import moment from 'moment';

// type DateProps = {
//   date: string;
// };

const FormattedDate = (date: string) => <div>{moment(date).format('LL')}</div>;

export default FormattedDate;
