import React from 'react';
import { Col, Row } from 'antd';

import { team, like, fast } from '../../assets';
import styles from './style.module.scss';

const APNBenefits = () => {
  return (
    <div className={styles.apnBenefits}>
      <h1>APN BENEFITS</h1>
      <Row gutter={12} className={styles.apnBenefitsRow}>
        <Col xs={24} sm={8} className={styles.apnBenefitsCol}>
          <img alt="easy-to-use-apn" src={team} />
          <h3>Easy to Use</h3>
          <p>Get an entire team up and running in minutes with an intuitive, easy-to-use interface.</p>
        </Col>
        <Col xs={24} sm={8} className={styles.apnBenefitsCol}>
          <img alt="fast-modern-apn" src={fast} />
          <h3>Fast and Modern</h3>
          <p>APN is built on a modern, secure and scalable cloud architecture, able to grow as your business grows.</p>
        </Col>
        <Col xs={24} sm={8} className={styles.apnBenefitsCol}>
          <img alt="affordable-subscription" src={like} />
          <h3>Affordable Subscription</h3>
          <p>APN’s flexible software-as-a-service model means far lower costs versus expensive license agreements.</p>
        </Col>
      </Row>
    </div>
  );
};

export default APNBenefits;
