import { gql } from '@apollo/client';

import { socialMedia, companyHeader, color, logos, footer, hero } from './fragments/company-fragments';
import { pricing, locations, companyInfo, mediaImages, product, mediaDocuments } from './fragments/common';

// returns a Company
// https://s3.amazonaws.com/apn-graphql-docs/dev/doc/schema/company.doc.html
export const company = gql(`
    query companyForDomain ($domainName: String) {
      companyByDomainName(domainName: $domainName){
        id
        name
        email
        phone
        ${socialMedia}
        ${logos}
        ${footer}
        ${companyHeader}
        ${hero}
        ${color}
      }  
    }
`);

// similar to other create other company related queries below
// like get company users and others
// Company Product Docs
// https://s3.amazonaws.com/apn-graphql-docs/dev/doc/schema/companyproduct.doc.html
export const globalParts = gql(`
     query searchCompanyProducts(
      $pageSize: Int!
      $pageNum: Int!
      $query: AWSJSON
      $sortBy: AWSJSON
    ) {
      searchCompanyProducts(
        pageSize: $pageSize
        pageNum: $pageNum
        sortBy: $sortBy
        query: $query
      ) {
        totalCount
        rows: results {
        ... on CompanyProduct {
           
            createdAt
            mediaDocuments {
              src
              type
            }
            mediaImages {
              src
              type
            }
          }
        }
       }
    }
`);

export const companyNetworks = gql(`
  query getCompanyNetworks {
    getCompanyNetworks {
      id
      userId
      companyId
      createdAt
      updatedAt
      config
      name
    }
  }
`);

export const networkPartSearch = gql(`
query marketPlaceNetworkPartSearch(
  $networkId: ID!
  $pageSize: Int!
  $pageNum: Int!
  $query: AWSJSON
  $sortBy: AWSJSON
  $filter: AWSJSON
) {
  marketPlaceNetworkPartSearch(
    networkId: $networkId
    pageSize: $pageSize
    pageNum: $pageNum
    sortBy: $sortBy
    query: $query
    filter: $filter
  ) {
    totalCount
    rows: results {
      ... on CompanyProductSummary {
          ${product}
          ${pricing}
          ${mediaImages}
          ${mediaDocuments}
          
          ${locations}
          
          ${companyInfo}
        }
      }
    }
  }
`);

export const networkSuggestSearch = gql(`
  query marketPlaceNetworkPartSuggest($networkId: ID!, $searchString: String!) {
    marketPlaceNetworkPartSuggest(networkId: $networkId, searchString: $searchString) {
      id
      partNumber
      description
      companyName
    }
   }
`);
