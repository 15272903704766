/* tslint:disable */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
export const getSQLQueryString = (query = {}, defaultQueryString: string) => {
  const queryObj = Object.keys(query).reduce(
    (acc, cur) => {
      if (Array.isArray(query[cur])) {
        if (query[cur].length) {
          const modifiedArray = query[cur].map((item) => `'${item}'`);
          const valueArr = `${cur} IN (${modifiedArray.toString()})`;
          acc.queryArr.push(valueArr);
        }
      } else {
        const test = `${cur} IN ('${query[cur]}')`;
        acc.queryStr.push(test);
      }
      return acc;
    },
    { queryArr: [], queryStr: [] },
  );
  const { queryArr, queryStr } = queryObj;
  let outputStr = '';
  if (!queryArr.length && !queryStr.length) {
    outputStr = '';
  } else if (!queryArr.length && queryStr.length) {
    outputStr = `${queryStr.join(' AND ')}`;
  } else if (queryArr.length && !queryStr.length) {
    outputStr = `${queryArr.join(' AND ')}`;
  } else {
    outputStr = `${queryArr.join(' AND ')} AND ${queryStr.join(' AND ')}`;
  }
  if (outputStr) {
    if (defaultQueryString) {
      outputStr = `${outputStr} AND ${defaultQueryString}`;
    }
    return JSON.stringify(`${outputStr}`);
  } else {
    return JSON.stringify(defaultQueryString);
  }
};
