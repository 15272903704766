import React, { useEffect } from 'react';
import { Table, Pagination } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { get, pickBy, identity } from 'lodash';
import { TablePaginationConfig } from 'antd/es/table';

import { DTActions } from '../../redux-modules/DataTable';
import { RootState } from '../../redux-modules/store/rootState';
import { DataTableProps } from './Types';
import { IDTParameters } from '../../common/libs/procedures/DataTableProcedure/Types';
import { TableRecordType } from '../../common/types/Types';
import styles from './style.module.scss';
import MobileEmptyAndLoader from '../MobileEmptyAndLoader';

const defaultPagination = {
  current: 1,
  pageSize: 200,
  pageSizeOptions: ['25', '50', '100'],
  showSizeChanger: true,
};

const { Column, ColumnGroup } = Table;

const DataTable = ({
  context,
  procedure,
  columns,
  parameters,
  pagination,
  tableOpts,
  rowSelection,
  expandable,
  isMobileResponsive,
  renderMobileRow,
  ...others
}: DataTableProps) => {
  const dispatch = useDispatch();

  const dataStore = useSelector((state: RootState) => state.DataTable[context]);

  // curry
  const take = (path: string) => get(dataStore, path);

  const tableParams: IDTParameters = take('parameters') || parameters;

  const { pageSize, current, filters } = tableParams;

  useEffect(() => {
    dispatch(
      DTActions.initDataTable({
        context,
        parameters,
        procedure,
      }),
    );
  }, []);

  useEffect(() => {
    dispatch(DTActions.fetchData({ context }));
  }, [pageSize, current, filters]);

  // TODO we need to fix this types, avoid using any I don't know how to fix this right now
  // eslint-disable-next-line
  const handleTableChange = (pagination: TablePaginationConfig, filters?: any, sorter?: any) => {
    console.log(pagination, filters, sorter);
    const updatedFilters = pickBy(filters, identity);
    dispatch(
      DTActions.setDataTableFilters({
        context,
        ...pagination,
        filters: updatedFilters,
        sorter: sorter,
      }),
    );
  };

  const isMobile = window.innerWidth <= 768;
  const rows = take('data.rows') || [];
  const isLoading = take('isLoading');
  const totalCount = take('data.totalCount');
  return (
    <div>
      {isMobileResponsive && isMobile ? (
        <>
          <div className={styles.mobileContainer}>
            <MobileEmptyAndLoader isLoading={isLoading} rowsLength={rows.length} />
            {!isLoading && rows.map((row: TableRecordType) => renderMobileRow(row))}
          </div>
          <Pagination
            showSizeChanger={true}
            pageSizeOptions={defaultPagination.pageSizeOptions}
            pageSize={pageSize || defaultPagination.pageSize}
            current={current || defaultPagination.current}
            total={totalCount > 1000 ? 1000 : totalCount}
            onChange={(page, pageSize) => handleTableChange({ current: page, pageSize }, filters)}
          />
        </>
      ) : (
        <Table
          rowKey="id"
          dataSource={rows}
          loading={isLoading}
          {...tableOpts}
          pagination={{
            ...defaultPagination,
            ...pagination,
            pageSize,
            current,
            total: totalCount > 1000 ? 1000 : totalCount,
          }}
          style={{ borderRadius: 0 }}
          onChange={handleTableChange}
          rowSelection={rowSelection}
          expandable={expandable}
          scroll={{ x: true }}
          {...others}
        >
          {columns.map((item: any, i) => {
            if (item?.columnType !== 'group') return <Column key={`column-index-${i}`} {...item} />;
            else
              return (
                <ColumnGroup title={item.title}>
                  {item.columns.map((col: any, i: number) => (
                    <Column key={`column-index-${i}`} {...col} />
                  ))}
                </ColumnGroup>
              );
          })}
        </Table>
      )}
    </div>
  );
};

export default DataTable;
