import { IRoute } from '../../common/types/Types';
import AppLayout from '../../layouts/AppLayout';
import NotFound from './NotFound';

export const NotFoundRoute: IRoute = {
  path: '/not-found',
  component: NotFound,
  layout: AppLayout,
  title: 'Page not found',
};
