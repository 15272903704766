import React from 'react';
import { Empty } from 'antd';

const NotFound = () => (
  <div>
    <Empty description="Page not found. Please contact admin" />
  </div>
);

export default NotFound;
