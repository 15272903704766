import { get } from 'lodash';

import { Money, INumberFormatOptions } from '../types/Types';

export const toCentsFromDollars = (n: number): number => {
  return Math.round(n * 100);
};

// given cents return value in dollars
export const toDollarsFromCents = (n: number): number => {
  return n / 100;
};

//  get only the dollars in an integer
export const getDollars = (n: number): string => {
  const dollars = toDollarsFromCents(n).toFixed(2).split('.')[0];
  return dollars;
};

//  get only the cents in an integer
export const getCents = (n: number): string => {
  return toDollarsFromCents(n).toFixed(2).split('.')[1];
};

export const centsToUSD = (n: number): string => {
  const dollars = getDollars(n);
  const cents = getCents(n);
  const currencySymbol = '$';
  return currencySymbol + (cents.match(/00/) ? dollars : `${dollars}.${cents}`);
};

export function formatMoney(price: Money, options?: INumberFormatOptions) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: get(price, 'currencyCode') || 'USD',
    minimumFractionDigits: 2,
    ...options,
  });
  return formatter.format(+get(price, 'amount', 0) / 100);
}

export function formatMoneyV2(money: Money, options?: INumberFormatOptions) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    // minimumFractionDigits: 4,
    maximumFractionDigits: 4,
    ...options,
  });
  const multiplier = get(money, 'multiplier') || 100;
  const amount = +get(money, 'amount') || 0;
  return formatter.format(amount / multiplier);
}

// via https://stackoverflow.com/questions/12004808/does-javascript-take-local-decimal-separators-into-account/42213804#42213804
export function unformatMoney(stringNumber: string) {
  const thousandSeparator = (1111).toLocaleString().replace(/1/g, '');
  const decimalSeparator = (1.1).toLocaleString().replace(/1/g, '');

  return parseFloat(
    stringNumber
      .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
      .replace(new RegExp('\\' + decimalSeparator), '.'),
  );
}
