import React, { useState } from 'react';
import { Form, Layout, Button, Row, Empty } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { ShoppingCartOutlined, SearchOutlined } from '@ant-design/icons';

/* eslint-disable */
import PageHeader from '../../layouts/Header/PageHeader';
import APNTable from '../../components/APNTable';
import { RootState } from '../../redux-modules/store/rootState';
import { Cart, CartItems } from '../../redux-modules/Global/Types';
import { Product } from '../../common/types/Types';
import { DeleteIcon } from '../../components/DummyHelpers/EditDelete';
import { DateField, InputField, SelectField, TextField } from '../../components/Form/FormFields';
import {createSRFQ, openModal, removeItemFromCart} from '../../redux-modules/Global/Actions';
import { AUTH_MODAL_ID } from '../../common/utils/constants';
import { getSRFQPayload } from '../../common/utils/helpers';
import history from '../../common/utils/history';
import moment, { Moment } from 'moment';

const PRIORITY_OPTIONS = [
  {
    text: 'Routine',
    value: 'ROUTINE',
  },
  {
    text: 'Expedited',
    value: 'EXPEDITED',
  },
  {
    text: 'AOG',
    value: 'AOG',
  },
];

const CartPage = () => {
  const dispatch = useDispatch();
  const cart: Cart = useSelector((state: RootState) => state.Global.cart);
  const isLoggedIn: boolean = useSelector((state: RootState) => state.Auth.loggedIn);
  const [isMobile] = useState(window.innerWidth <= 768);
  const cartItems: CartItems = cart.items;

  const take = (path: string) => get(cart, path);

  const handleDelete = (record: Product) => {
    dispatch(removeItemFromCart(record))
  };

  const onFormFinish = (values: Record<string, unknown>) => {
    const srfqPayload = getSRFQPayload({ lineItems: cartItems, values });
    dispatch(createSRFQ(srfqPayload));
  };

  const handleLogin = () => {
    dispatch(openModal(AUTH_MODAL_ID));
  };

  const RenderDelete = (x: string, record: Product) => <DeleteIcon handleDelete={() => handleDelete(record)} />;

  const RenderPartAndDesc = (partNumber: string, record: Product) => (
      <div>{partNumber} - {get(record, 'description')}</div>
  )

  const RenderCompanyName = (company: any) => get(company, 'companyName');

  const RenderMobileCartData = (x: string, record: Product) => (
    <>
      <div style={{fontWeight: 'bold'}}>
        {get(record, 'partNumber')} - {get(record, 'description')}
      </div>
      <div>
        CC: {get(record, 'CC')} Qty. {get(record, 'quantity')} Price. {get(record, 'price', 'RFQ')} 
      </div>
    </>
  );

  const disabledDate = (current: Moment) => {
    // Can not select days before today
    return current < moment().subtract(1, 'day');
  }
  
  return (
    <>
      <PageHeader title="RFQ Cart" />
      <Layout.Content style={{ margin: 20, padding: 20, backgroundColor: 'white', minHeight: 500 }}>
        {cartItems.length > 0 ? (
          <>
            <APNTable
              dataSource={cartItems}
              rowKey="id"
              columns={isMobile ? [  {
                title: 'Part #/ Description',
                dataIndex: 'partNumber',
                width: '80%',
                render: RenderMobileCartData,
              },                 {
                title: '',
                dataIndex: 'id',
                width: '20%',
                render: RenderDelete,
              },]:[
                {
                  title: 'Part #/ Description',
                  dataIndex: 'partNumber',
                  render: RenderPartAndDesc,
                },
                {
                  title: 'Supplier',
                  dataIndex: 'companyInfo',
                  render: RenderCompanyName,
                },
                {
                  title: 'CC',
                  dataIndex: 'CC',
                },
                {
                  title: 'Qty',
                  dataIndex: 'quantity',
                },
                {
                  title: '',
                  dataIndex: 'id',
                  render: RenderDelete,
                },
              ]}
            />
            <Form name="login" layout="vertical" onFinish={onFormFinish}>
              <Row gutter={12} style={{ marginTop: 10 }}>
                <DateField required name="expiryDate" label="Quote Required By" disabledDate={disabledDate} />
                <DateField required name="deliveryRequiredBy" label="Delivery Required By" disabledDate={disabledDate} />
                <SelectField options={PRIORITY_OPTIONS} name="priority" label="Priority"  />
                <InputField name="trackingNumber" label="RFQ Tracking Number"  />
                <TextField name="comments" label="Comments" span={24} />
              </Row>
              {isLoggedIn ? (
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    size="large"
                    style={{ width: 200 }}
                    loading={take('isLoading')}
                  >
                    Send RFQ
                  </Button>
                </Form.Item>
              ) : (
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  size="large"
                  style={{ width: 200 }}
                  onClick={handleLogin}
                >
                  Login To Send RFQ
                </Button>
              )}
            </Form>
          </>
        ) : (
          <Empty
            image={<ShoppingCartOutlined />}
            imageStyle={{ fontSize: 100, height: 130, color: '#595959' }}
            description={
              <>
                <h3>Your cart is empty, click below button to search and add items.</h3>
              </>
            }
          >
            <Button icon={<SearchOutlined />} onClick={() => history.push('/search')}>
              Search
            </Button>
          </Empty>
        )}
      </Layout.Content>
    </>
  );
};

export default CartPage;
