import {
  USER_INFO_COMPLETE,
  USER_LOGIN,
  USER_LOGIN_SET_LOADING,
  USER_LOGIN_COMPLETE,
  GET_USER_COMPLETE,
  LOG_OUT_COMPLETE,
  USER_LOGIN_SET_LOADING_COMPLETE,
} from './Actions';
import { AuthState } from './Types';
import { ReduxAction } from '../../common/types/Types';

const initialState: AuthState = {
  user: {
    name: '',
  },
  isLoading: false,
  loggedIn: false,
};

export default (state = initialState, action: ReduxAction) => {
  const { type, payload } = action;
  switch (type) {
    case USER_LOGIN:
      return {
        ...state,
        isLoading: true,
      };
    case USER_INFO_COMPLETE:
      return {
        ...state,
        isLoading: false,
      };
    case USER_LOGIN_SET_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case USER_LOGIN_SET_LOADING_COMPLETE:
      return {
        ...state,
        isLoading: false,
      };
    case USER_LOGIN_COMPLETE:
      return {
        ...state,
        isLoading: false,
        user: payload,
        loggedIn: true,
      };
    case GET_USER_COMPLETE: {
      console.log(payload);
      return {
        ...state,
        user: payload,
      };
    }
    case LOG_OUT_COMPLETE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
