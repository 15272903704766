import { IRoute } from '../../common/types/Types';
import AppLayout from '../../layouts/AppLayout';
import Buyers from './Buyers';

export const BuyersRoute: IRoute = {
  path: '/buyers',
  component: Buyers,
  layout: AppLayout,
  exact: true,
  title: 'Buyers',
};
