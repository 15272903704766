import React, { ReactElement, useState } from 'react';
import { Badge, Layout, Dropdown, Menu, Button } from 'antd';
import {
  ShoppingCartOutlined,
  MenuOutlined,
  SearchOutlined,
  CloseCircleOutlined,
  HistoryOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { get } from 'lodash';

import styles from './Header.module.css';
import { whiteApnLogo, apnLogoMobile } from '../../assets';
import { logoutUser } from '../../redux-modules/Auth/Actions';
import { RootState } from '../../redux-modules/store/rootState';
import { openModal, openDrawer, closeDrawer } from '../../redux-modules/Global/Actions';
import { PROFILE_MODAL_ID, HEADER_MENU_DRAWER_ID } from '../../common/utils/constants';
import { splitName } from '../../common/utils/helpers';
import history from '../../common/utils/history';
import APNDrawer from '../../components/APNDrawer';

type MenuProps = {
  label: string;
  path: string;
};

const MenuItems: Array<MenuProps> = [
  {
    label: 'SEARCH',
    path: '/search',
  },
  {
    label: 'RFQ HISTORY',
    path: '/history',
  },
  {
    label: 'BUYERS',
    path: '/buyers',
  },
  {
    label: 'SELLERS',
    path: '/sellers',
  },
  {
    label: 'STOREFRONT',
    path: '/storefront',
  },
];

const mobileLinks = () => [
  {
    icon: <SearchOutlined />,
    label: 'SEARCH',
    path: '/search',
  },
  {
    icon: <HistoryOutlined />,
    label: 'RFQ HISTORY',
    path: '/history',
  },
  {
    icon: <HistoryOutlined />,
    label: 'BUYERS',
    path: '/buyers',
  },
  {
    icon: <HistoryOutlined />,
    label: 'SELLERS',
    path: '/sellers',
  },
  {
    icon: <HistoryOutlined />,
    label: 'STOREFRONT',
    path: '/storefront',
  },
];

const Header = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isMobile] = useState(window.innerWidth <= 768);

  const width = screen.width;

  const selectedKey: Array<string> = [];
  MenuItems.map((item) => {
    if (location.pathname.includes(item.path)) selectedKey.push(item.label);
  });

  const cartItemsLength: number = useSelector((state: RootState) => {
    // FIXME
    let count = 0;
    state.Global.cart.items.map((value) => (count += value.quantity));
    return count;
  });

  const authState = useSelector((state: RootState) => state.Auth);

  const take = (path: string) => get(authState, path);

  const menu = (
    <Menu style={{ minWidth: 150 }}>
      <Menu.Item key="0" onClick={() => dispatch(openModal(PROFILE_MODAL_ID))}>
        Profile
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="1" onClick={() => dispatch(logoutUser())}>
        Log Out
      </Menu.Item>
    </Menu>
  );
  const user = take('user');
  const userName = splitName(user.name);

  const closeDrawerAndNavigate = (path: string) => {
    dispatch(closeDrawer(HEADER_MENU_DRAWER_ID));
    history.push(path);
  };

  const RenderLogo = ({ children }: { children: ReactElement }) => (
    <a href="https://www.aeropartsnow.com/home-search">{children}</a>
  );
  console.log(selectedKey, location);
  return (
    <Layout.Header className={styles.container}>
      {!isMobile && (
        <>
          <div className={styles.headerContainer}>
            <div>
              <RenderLogo>
                <img alt="APNSync" src={whiteApnLogo} className={styles.logo} />
              </RenderLogo>
            </div>
            <div style={{ alignContent: 'center', height: '100%', maxHeight: '8vh' }}>
              <Menu
                mode="horizontal"
                theme="dark"
                defaultSelectedKeys={selectedKey}
                style={{
                  background: '#1F3D57',
                  color: '#ffffff',
                  height: '100%',
                }}
              >
                {MenuItems.map(({ label, path }: MenuProps) => (
                  <Menu.Item
                    key={label}
                    onClick={() => history.push(path)}
                    style={{
                      fontSize: 18,
                      height: '100%',
                      backgroundColor: selectedKey.includes(label) ? 'white' : '#1F3D57',
                      color: selectedKey.includes(label) ? '#1890ff' : 'white',
                      fontWeight: 600,
                      borderBottom: selectedKey.includes(label) ? '2px solid #1890ff' : '',
                      // background: selectedKey.includes(label) ? 'linear-gradient(white, white, white, #a7c5e1)' : '',
                    }}
                  >
                    {label}
                  </Menu.Item>
                ))}
              </Menu>
            </div>
            <div className={styles.buttonContainer}>
              <Link to="/cart">
                <Badge count={cartItemsLength}>
                  <ShoppingCartOutlined type="cart" className={styles.cartLogo} />
                </Badge>
              </Link>
              {take('loggedIn') && (
                <Dropdown overlay={menu} trigger={['click']}>
                  <div className={styles.dropDownIcon}>{(userName[0] || '').toUpperCase()}</div>
                </Dropdown>
              )}
            </div>
          </div>
        </>
      )}

      {isMobile && (
        <>
          <RenderLogo>
            <img alt="APNSync" src={apnLogoMobile} className={styles.logo} />
          </RenderLogo>
          <span className={styles.buttonContainer}>
            <Link to="/cart">
              <Badge count={cartItemsLength}>
                <ShoppingCartOutlined type="cart" className={styles.cartLogo} />
              </Badge>
            </Link>
            {take('loggedIn') && (
              <Dropdown overlay={menu} trigger={['click']}>
                <div className={styles.dropDownIcon}>{(userName[0] || '').toUpperCase()}</div>
              </Dropdown>
            )}
            <Button
              icon={<MenuOutlined />}
              className={styles.headerIcon}
              onClick={() => dispatch(openDrawer(HEADER_MENU_DRAWER_ID))}
              style={{ background: '#1F3D57', color: 'white' }}
            />
          </span>
        </>
      )}
      <APNDrawer
        id={HEADER_MENU_DRAWER_ID}
        onClose={() => dispatch(closeDrawer(HEADER_MENU_DRAWER_ID))}
        width={isMobile ? '100vw' : width}
        height="100vh"
        closable={false}
        bodyStyle={{ padding: 0 }}
      >
        <>
          <div style={{ height: 100 }}>
            <Button
              className={styles.closeIcon}
              icon={<CloseCircleOutlined />}
              onClick={() => dispatch(closeDrawer(HEADER_MENU_DRAWER_ID))}
            />
          </div>
          <div style={{ height: 400 }}>
            {(mobileLinks() || []).map(({ icon, label, path }, index) => (
              <Button
                key={`option-${index}`}
                icon={icon}
                onClick={() => closeDrawerAndNavigate(path)}
                className={styles.menuItem}
              >
                {label}
              </Button>
            ))}
          </div>
        </>
      </APNDrawer>
    </Layout.Header>
  );
};

export default Header;
