import { IRoute } from '../../common/types/Types';
import History from './History';
import AppLayout from '../../layouts/AppLayout';

export const HistoryRoute: IRoute = {
  path: '/history',
  component: History,
  layout: AppLayout,
  title: 'History',
  exact: true,
};
