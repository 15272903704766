export const address = `
  address {
    address1
    address2
    city
    countryCode
    stateCode
    province
    zip
  }
`;

export const userAttributes = `
  name
  id
  email
  companyId
  username
  phone
  fax
  title
`;

export const locations = `
locations { 
  id 
  name 
  address {
    address1
    city
    country
  }
}`;

const basicMedia = `
  src
  id
  fileName
`;

export const mediaDocuments = `
mediaDocuments {
  ${basicMedia}
}`;
export const mediaImages = `
mediaImages {
  ${basicMedia}
}`;

export const featuredImage = `
featuredImage {
  ${basicMedia}
}`;

export const companyInfo = `
companyInfo {
  companyName
  id
  email
  storefront {
   storefrontDomainName
  }
}`;

export const product = `
    id
    partNumber   
    description   
    altPartNumbers
    totalQuantity
    qtyAndPriceByCC
    totalQuantity
    hasImagesOnStock
    hasDocsOnStock
    locationSummary {
      name
      address {
        address1
        geoPoint {
          lat
          lon
        }
      }
      conditionSummary
   }
    locations {
      name
      address {
       address1
      }
    }
    featuredImage {
      id
      fileName
      src
    }
`;

export const moneyFragment = `
  amount
  currencyCode
  multiplier
`;

export const pricing = `
  pricing {
    listPrice {
      ${moneyFragment}
    }
  }
`;

export const exchangePricing = `
  exchangePricing {
    listPrice {
      amount
       currencyCode
    }
    mfgListPrice {
        amount
        currencyCode
    }
    ovhlCostPrice {
        amount
        currencyCode
    }
    soCoreChargePrice {
        amount
        currencyCode
    }
    coreValuePrice {
        amount
        currencyCode
    }
    poCoreChargePrice {
        amount
        currencyCode
    }
  }
`;

export const timestamps = `
  createdAt
  updatedAt
`;

export const lineItems = `
 lineItems {
   id
   partNumber
   requestedQty
   conditionCode
   description
   productId
   comments
 }`;

export const assignedTo = `
  assignedTo {
    id
    name
  }
`;

export const networkCompany = `
 companyList { 
  id
  name
  }
`;

export const network = `
    id
    userId
    companyId
    createdAt
    updatedAt
    config
    name
    productsFilteringRule
    ${networkCompany}
`;
