import React, { useEffect, useState } from 'react';
import { Tabs, Layout, Row, Col, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';
import { Analytics } from 'aws-amplify';
import { FilterFilled, SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';

import { Product, TableRecordType } from '../../common/types/Types';
import { addItemInCart, closeDrawer, openDrawer } from '../../redux-modules/Global/Actions';
import PartListDT from './components/PartListDT';
import SearchBox from '../../components/SearchBox';
import { SearchProcedure } from '../../common/libs/procedures/SearchProcedure/SearchProcedure';
import RepairStations from './components/RepairStations';
import { networkSuggestSearch } from '../../queries/company';
import { FILTER_INVENTORY_DRAWER_ID, networkId } from '../../common/utils/constants';
import ConditionCodeSelector from '../../components/ConditionCodeSelector';
import styles from './style.module.scss';
import GetStartedBanner from '../../components/Banners/GetStartedBanner';
import { RootState } from '../../redux-modules/store/rootState';
import APNDrawer from '../../components/APNDrawer';
import { DTActions } from '../../redux-modules/DataTable';

type ParamsType = {
  partNumber: string;
};

const { TabPane } = Tabs;

const Search = () => {
  const dispatch = useDispatch();
  const { partNumber = '' } = useParams<ParamsType>();
  const dataTableState = useSelector((state: RootState) => state.DataTable['search']);
  const filters = get(dataTableState, 'parameters.filters');
  const partNumbers = get(filters, 'partNumber', []);
  const conditions = get(filters, 'conditionCodes', []);

  const parts = (partNumber && [partNumber]) || partNumbers;
  const [selectedParts, setSelectedParts] = useState<Array<string>>([...parts]);
  const [tabIndex, setTabIndex] = useState('1');
  const [sortType, setSortType] = useState('asc');

  const [sortFilter, setSortFilter] = useState({
    isSort: false,
    isFilter: false,
  });

  useEffect(() => {
    // if (partNumber) {
    //   setSelectedParts([...selectedParts, partNumber]);
    // }

    try {
      Analytics.record({ name: 'search screen' });
    } catch (e) {
      console.log(e, 'analytics error');
    }
  }, []);

  const handleAddItemToCart = (item: TableRecordType) => {
    const cartItem: Product = {
      id: item.id,
      partNumber: item.partNumber,
      quantity: 1,
      CC: item.CC,
      companyInfo: item.companyInfo,
      description: item.description,
      uom: get(item, 'uom', ''),
      bin: get(item, 'bin', ''),
    };
    dispatch(addItemInCart(cartItem));
  };

  const handleSelect = (part: string) => {
    setSelectedParts([...selectedParts, part]);
  };

  const handleDeSelect = (part: string) => {
    const temp = [...selectedParts];
    const index = selectedParts.findIndex((item: string) => item === part);
    temp.splice(index, 1);
    setSelectedParts([...temp]);
  };

  const parameters = {
    query: networkSuggestSearch,
    networkId: networkId,
  };

  const openDrawerById = (id: string) => {
    dispatch(openDrawer(id));
    setSortFilter({ isSort: false, isFilter: true });
  };

  const closeDrawerById = (id: string) => {
    dispatch(closeDrawer(id));
    setSortFilter({ isSort: false, isFilter: false });
  };

  const handleCustomSort = (order: string) => {
    setSortType(order);
    dispatch(
      DTActions.setDataTableFilters({
        context: 'search',
        filters: {
          sortBy: {
            partNumber: order,
          },
        },
      }),
    );
  };

  const isMobile = window.innerWidth <= 768;

  return (
    <>
      <GetStartedBanner />
      <Layout.Content style={{ padding: 20 }}>
        <Row style={{ padding: 20, backgroundColor: 'white' }}>
          <Col sm={12} xs={24}>
            <SearchBox
              style={{ width: '100%', border: 'none', borderBottom: '1px solid #d9d9d9' }}
              defaultValue={selectedParts.length > 0 ? selectedParts : []}
              parameters={parameters}
              context="search"
              procedure={SearchProcedure}
              bordered={false}
              onSelect={handleSelect}
              onDeselect={handleDeSelect}
              mode="multiple"
              getPopupContainer={(trigger) => trigger.parentNode}
            />
          </Col>
          {tabIndex === '1' && !isMobile && (
            <Col sm={12} xs={24} className={styles.conditionCodes}>
              <ConditionCodeSelector context="search" selectedItems={conditions} />
            </Col>
          )}
        </Row>
        {isMobile && tabIndex === '1' && (
          <Row className={styles.sortFilterContainer}>
            <Button
              className={styles.actionButton}
              onClick={() => handleCustomSort(sortType === 'asc' ? 'desc' : 'asc')}
            >
              {sortType === 'asc' ? <SortDescendingOutlined /> : <SortAscendingOutlined />} SORT
            </Button>
            <Button className={styles.actionButton} onClick={() => openDrawerById(FILTER_INVENTORY_DRAWER_ID)}>
              <FilterFilled /> FILTER
            </Button>
          </Row>
        )}
        <Tabs
          defaultActiveKey={tabIndex}
          onChange={(activeKey: string) => setTabIndex(activeKey)}
          tabBarStyle={{ backgroundColor: 'white', paddingLeft: 10, marginBottom: 0 }}
        >
          <TabPane tab="Part Listings" key="1">
            <PartListDT addItemToCart={handleAddItemToCart} partNumbers={selectedParts} filters={filters} />
          </TabPane>
          <TabPane tab="Repair Stations" key="2">
            <RepairStations partNumber={selectedParts} />
          </TabPane>
        </Tabs>
      </Layout.Content>

      <APNDrawer
        id={FILTER_INVENTORY_DRAWER_ID}
        placement="bottom"
        onClose={() => closeDrawerById(FILTER_INVENTORY_DRAWER_ID)}
        height={400}
        bodyStyle={{ padding: 10 }}
      >
        <>
          {sortFilter.isFilter && (
            <div>
              <h3>FILTER INVENTORY</h3>
              <h5 style={{ marginBottom: 20 }}>Filter by Condition Code</h5>
              <ConditionCodeSelector context="search" selectedItems={conditions} />
              <Button
                type="primary"
                size="large"
                className={styles.closeButton}
                onClick={() => closeDrawerById(FILTER_INVENTORY_DRAWER_ID)}
              >
                CLOSE
              </Button>
            </div>
          )}
        </>
      </APNDrawer>
    </>
  );
};

export default Search;
