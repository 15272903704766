import { put, select, takeLatest, call } from 'redux-saga/effects';
import { notification } from 'antd';
import { get } from 'lodash';

import {
  ADD_CART_ITEM,
  CLOSE_MODAL,
  CREATE_SRFQ,
  createSRFQComplete,
  GET_NETWORK_INFO,
  getNetworkInfo,
  LOAD_GLOBAL_DATA,
  REMOVE_ITEM_FROM_CART,
  setCartLoading,
  setCartLoadingComplete,
  updateCart,
  updateModalList,
  updateNetwork,
  CLOSE_DRAWER,
  updateDrawerList,
} from './Actions';
import { GraphQLResult, Network, Product } from '../../common/types/Types';
import { ISagaAction } from '../Types';
import BaseService from '../../api/BaseService';
import { createSRFQQuery, getNetwork } from '../../queries';
import history from '../../common/utils/history';
import { networkId } from '../../common/utils/constants';

function* updateCartSaga({ payload }: ISagaAction<Product>) {
  yield put(setCartLoading());
  try {
    const cartItems: Array<Product> = yield select((state) => state.Global.cart.items);
    const index = cartItems.findIndex((item) => item.id === payload.id && item.CC === payload.CC);
    if (index > -1) {
      cartItems[index].quantity = +cartItems[index].quantity + 1;
    } else {
      cartItems.push(payload);
    }
    yield put(updateCart(cartItems));
    yield put(setCartLoadingComplete());
    notification.success({ message: 'Updated Cart!' });
  } catch (e) {
    notification.error({ message: 'Something went wrong.', description: 'Please try again after sometime' });
  }
}

function* closeModalSaga({ payload }: ISagaAction<string>) {
  const modalsList: Array<string> = yield select((state) => state.Global.modals);
  const index = modalsList.findIndex((id) => id === payload);
  if (index > -1) {
    modalsList.splice(index, 1);
    yield put(updateModalList(modalsList));
  }
}

function* createSRFQSaga({ payload }: ISagaAction<any>) {
  try {
    yield put(setCartLoading());
    yield call(() =>
      BaseService.auth({
        query: createSRFQQuery,
        variables: payload,
      }),
    );
    yield put(createSRFQComplete());
    history.push('/history');
  } catch (e) {
    console.log(e);
    yield put(setCartLoadingComplete());
  }
}

function* loadGlobalDataSaga() {
  console.log('global data load');
  try {
    yield put(getNetworkInfo());
  } catch (e) {
    console.log(e);
  }
}

function* removeItemFromCartSaga({ payload }: ISagaAction<Product>) {
  try {
    const cartItems: Array<Product> = yield select((state) => state.Global.cart.items);
    const index = cartItems.findIndex((item) => item.id === payload.id && item.CC === payload.CC);
    if (index > -1) {
      cartItems.splice(index, 1);
      yield put(updateCart([...cartItems]));
    }
    notification.success({ message: 'Updated cart!' });
  } catch (e) {
    console.log(e);
  }
}

function* getNetworkSaga() {
  try {
    const res: GraphQLResult = yield call(() =>
      BaseService.public({
        query: getNetwork,
        variables: {
          networkId: networkId,
        },
      }),
    );
    const network: Network = get(res, 'data.getNetwork');
    yield put(updateNetwork(network));
  } catch (e) {
    console.log(e);
  }
}

function* closeDrawerSaga({ payload }: ISagaAction<string>) {
  const drawersList: Array<string> = yield select((state) => state.Global.drawers);
  const index = drawersList.findIndex((id) => id === payload);
  if (index > -1) {
    drawersList.splice(index, 1);
    yield put(updateDrawerList(drawersList));
  }
}

export default function* globalSagas() {
  yield takeLatest(ADD_CART_ITEM, updateCartSaga);
  yield takeLatest(CLOSE_MODAL, closeModalSaga);
  yield takeLatest(CREATE_SRFQ, createSRFQSaga);
  yield takeLatest(LOAD_GLOBAL_DATA, loadGlobalDataSaga);
  yield takeLatest(REMOVE_ITEM_FROM_CART, removeItemFromCartSaga);
  yield takeLatest(GET_NETWORK_INFO, getNetworkSaga);
  yield takeLatest(CLOSE_DRAWER, closeDrawerSaga);
}
