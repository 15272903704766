import { gql } from '@apollo/client';

import { lineItems, network } from './fragments/common';

const supplierRFQAttributes = `
    ${lineItems}
    id
    supplierRfqNumber
    status
    expiryDate
    leadTime
    buyerRfqId
    description
    quotesReceived
    requestedParts
    rfqSource
    requestedSuppliers {
     accountId
     contactId
     toEmail
    }
`;

export const searchSupplierRFQs = gql(`
query searchSupplierRFQS($pageSize: Int!, $pageNum: Int!, $query: AWSJSON, $sortBy: AWSJSON) {
      searchSupplierRFQS(pageSize: $pageSize, pageNum: $pageNum, sortBy: $sortBy, query: $query) {
        totalCount
        rows: results {
          ... on SupplierRFQ {
            ${lineItems}
            id
            supplierRfqNumber
            status
            expiryDate
            leadTime
            buyerRfqId
            description
            quotesReceived
            requestedParts
            rfqSource
            createdAt
            requestedSuppliers {
             accountId
             contactId
             toEmail
             accountName
            }
          }
        }
      }
    }`);

export const createSRFQQuery = gql(`
    mutation createAndSendSupplierRFQ($input: SupplierRFQInput!){
        createAndSendSupplierRFQ(input: $input){
         ${supplierRFQAttributes}
       }
     }
`);

export const sendSupplierRFQ = gql(`
    mutation sendSupplierRFQ($supplierQuoteId: ID!,$comments: String, $subject: String, $requestedSuppliers: [RequestedSupplierInput!]!) {
      sendSupplierRFQ(supplierQuoteId: $supplierQuoteId,comments: $comments, subject: $subject, requestedSuppliers: $requestedSuppliers)
    }
`);

export const getUserProfile = gql(`
    query user {
        user {
          name
          email
        }
      }
    `);

export const getNetwork = gql(`
    query getNetwork($networkId: ID!) {
      getNetwork(networkId: $networkId) {
        ${network}
      }
    }
`);
