import apnLogoIcon from './images/aeroparts_logo.png';
import imageIconLogo from './images/image-icon.png';
import apnFooterIcon from './images/apn-connectionsbg-02.jpg';
import apnLogoWhite from './images/apn-logo-white.png';
import apnLogoMobileIcon from './images/APNIcon.png';
import apnBannerBackground from './images/apn-bannerbg.jpg';
import buyersHeroBgImage from './images/apn-buyersherobg-02.jpg';
import searchBannerIcon from './images/search-banner.png';
import sellersHeroBgImage from './images/apn-sellersherobg-02.jpg';
import quoteBackgroundBuyerImage from './images/apn-quotebackgroundtemplatepurple-01.jpg';
import fastIcon from './images/icon-fast.png';
import likeIcon from './images/icon-like.png';
import teamIcon from './images/icon-team.png';
import videoPlaceHolderIcon from './images/apn-videoplaceholder.jpg';
import apnSearchIcon from './images/apn-apnsearchdetail.jpg';
import apnProcurementIcon from './images/apn-procurementdetail.jpg';
import apnCurrencyIcon from './images/apn-quotingcurrency.jpg';
import socialDetailsIcon from './images/apn-socialpostdetail-01.jpg';
import syncDetailsIcon from './images/apn-syncdetail-01.jpg';
import brandingIcon from './images/apn-brandingdetail-01.jpg';
import quoteBackgroundStoreFront from './images/apn-quotebackgroundtemplategreen-01.jpg';
import manageAccessIcon from './images/apn-lockunlockdetail-01.jpg';
import multipleStoreFrontIcon from './images/apn-multiplestorefrontsdetail-01.jpg';
import storeFrontHeroBgIcon from './images/apn-storefrontsherobg-02.jpg';
import rfqInboxIcon from './images/apn-rfqinboxdetail-01.jpg';
import quoteBackgroundBlueImage from './images/apn-quotebackgroundtemplateblue.jpg';

export const imageIcon = imageIconLogo;
export const apnLogo = apnLogoIcon;
export const apnFooter = apnFooterIcon;
export const whiteApnLogo = apnLogoWhite;
export const apnLogoMobile = apnLogoMobileIcon;
export const apnBanner = apnBannerBackground;
export const buyersHeroBg = buyersHeroBgImage;
export const sellersHeroBg = sellersHeroBgImage;
export const searchBanner = searchBannerIcon;
export const quoteBackgroundBuyer = quoteBackgroundBuyerImage;
export const fast = fastIcon;
export const like = likeIcon;
export const team = teamIcon;
export const videoPlaceHolder = videoPlaceHolderIcon;
export const apnSearch = apnSearchIcon;
export const apnProcurement = apnProcurementIcon;
export const apnCurrency = apnCurrencyIcon;
export const socialDetails = socialDetailsIcon;
export const syncDetails = syncDetailsIcon;
export const branding = brandingIcon;
export const storeFrontBackground = quoteBackgroundStoreFront;
export const manageAccess = manageAccessIcon;
export const multipleStoreFront = multipleStoreFrontIcon;
export const storeFrontHeroBg = storeFrontHeroBgIcon;
export const rfqInbox = rfqInboxIcon;
export const quoteBackgroundBlue = quoteBackgroundBlueImage;
