import React, { useState } from 'react';
import { Tooltip, Button } from 'antd';
import { useDispatch } from 'react-redux';

import styles from './styles.module.scss';
import { DTActions } from '../../redux-modules/DataTable';

type Props = {
  context: string;
  selectedItems?: Array<string>;
};

const ConditionSelector = ({ context, selectedItems = [] }: Props) => {
  const [selected, setSelected] = useState<Array<string>>([...selectedItems]);
  const [conditionCodeList] = useState([
    { text: 'Serviceable', value: 'SV' },
    { text: 'Overhauled', value: 'OH' },
    { text: 'REPAIR', value: 'RE' },
    { text: 'New', value: 'NE' },
    { text: 'New Surplus', value: 'NS' },
    { text: 'USED', value: 'US' },
    { text: 'Repairable', value: 'RP' },
    { text: 'As Removed', value: 'AR' },
    { text: 'Factory New', value: 'FN' },
    { text: 'REPAIR', value: 'REP' },
    { text: 'INSPECTED', value: 'IN' },
  ]);

  const dispatch = useDispatch();

  const handleConditionToggle = (value: string) => {
    let draft: Array<string> = selected;
    if (draft.includes(value)) draft = draft.filter((v) => v !== value);
    else draft.push(value);
    setSelected([...draft]);
    const initialPage = {
      current: 1,
    };
    dispatch(
      DTActions.setDataTableFilters({
        context,
        ...initialPage, // TODO why this work I have no idea, need to understand
        filters: {
          conditionCodes: [...draft],
        },
      }),
    );
  };

  return (
    <div className={styles.conditionSelector}>
      <div className={styles.container}>
        <div className={styles.conditionHeader}>Conditions </div>
        {conditionCodeList.map(({ text, value }: any) => (
          <Tooltip title={text} key={value}>
            <Button
              onClick={(event) => {
                handleConditionToggle(value);
                event.currentTarget.blur();
              }}
              className={selected.includes(value) ? styles.selected : ''}
            >
              {value}
            </Button>
          </Tooltip>
        ))}
      </div>
    </div>
  );
};

export default ConditionSelector;
