import React from 'react';
import { Button, Col, Row } from 'antd';

import styles from './style.module.scss';

type ButtonProps = {
  type: 'default' | 'link' | 'text' | 'ghost' | 'primary' | 'dashed' | undefined;
  label: string;
  redirectionLink: string;
};

type Props = {
  backgroundImage: string;
  header: string;
  subheader: string;
  textTheme: 'dark' | 'light';
  button?: ButtonProps;
};

const APNBenefits = ({ backgroundImage, header, subheader, textTheme, button }: Props) => {
  return (
    <div className={styles.weAreDiffBannerStyle} style={{ backgroundImage: `url(${backgroundImage})` }}>
      <Row className={styles.weAreDiffBannerChildStyle}>
        <Col span={24}>
          <div className={textTheme === 'dark' ? styles.dark : styles.light} style={{ width: '100%' }}>
            <h1>{header}</h1>
            <h4>{subheader}</h4>
          </div>
        </Col>
        {button && (
          <Col span={24} style={{ textAlign: 'center' }}>
            <a href={button.redirectionLink} target="blank">
              <Button type={button.type} className={styles.scheduleDemoButton}>
                {button.label}
              </Button>
            </a>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default APNBenefits;
