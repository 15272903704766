import { IRoute } from '../../common/types/Types';
import SignIn from './SignIn';
import StaticLayout from '../../layouts/StaticLayout';

export const SignInRoute: IRoute = {
  path: '/sign-in',
  component: SignIn,
  layout: StaticLayout,
  exact: true,
  title: 'Sign-in',
};
