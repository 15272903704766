import { IRoute } from '../../common/types/Types';
import Dashboard from './Dashboard';
import AppLayout from '../../layouts/AppLayout';

export const DashboardRoute: IRoute = {
  path: '/dashboard',
  component: Dashboard,
  layout: AppLayout,
  exact: true,
  title: 'Dashboard',
};
