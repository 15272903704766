import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

import APNModal from '../Modals';
import { AUTH_MODAL_ID } from '../../common/utils/constants';
import { InputField, PasswordField } from '../Form/FormFields';
import { closeModal } from '../../redux-modules/Global/Actions';
import { createUser, loginUser } from '../../redux-modules/Auth/Actions';
import { RootState } from '../../redux-modules/store/rootState';

type Props = {
  // eslint-disable-next-line no-unused-vars
  handleFormChange: (state: string) => void;
  // eslint-disable-next-line no-unused-vars
  getFieldValue?: any;
};

const TITLE_MAPPER: Record<string, string> = {
  signIn: 'Sign In',
  signUp: 'Sign Up',
  forgotPassword: 'Forgot Password',
};

const SignIn = ({ handleFormChange }: Props) => (
  <>
    <InputField
      name="username"
      required
      label="Email"
      span={24}
      rules={[{ required: true, message: 'Email is required', type: 'email' }]}
    />
    <PasswordField
      name="password"
      required
      label="Password"
      span={24}
      // forgotPasswordAction={() => handleFormChange('forgotPassword')}
    />
    <Col span={24}>
      <h3 style={{ width: '100%' }}>Need an account?</h3>
      <Button type="link" onClick={() => handleFormChange('signUp')} style={{ textAlign: 'left', paddingLeft: 0 }}>
        Create Account
      </Button>
    </Col>
  </>
);

const CreateAccount = ({ handleFormChange, getFieldValue }: Props) => {
  const checkPassword = (rule: string, value: string, callback: (message?: string) => void) => {
        const regex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$') // eslint-disable-line
    const validPassword = regex.test(value);

    let message = '';
    if (!value) {
      message = 'Please enter password';
    } else if (!validPassword || value.length < 8) {
      message =
        'Password minimum length should be 8 and should contain one number, one special character, one upper case letter.';
    }
    message ? callback(message) : callback();
  };

  const checkConfirmPassword = (rule: string, value: string, callback: (message?: string) => void) => {
    const password = getFieldValue('password') || '';

    let message = '';
    if (!value) {
      message = 'Please enter confirm password';
    } else if (value !== password) {
      message = 'Password did not match!';
    }
    message ? callback(message) : callback();
  };

  return (
    <>
      <InputField name="name" required label="Name" span={12} />
      <InputField name="email" required label="Email" span={12} />
      <PasswordField
        name="password"
        required
        label="Password"
        span={12}
        rules={[
          {
            validator: checkPassword,
            required: true,
          },
        ]}
      />
      <PasswordField
        name="confirmPassword"
        required
        label="Confirm Password"
        span={12}
        rules={[
          {
            validator: checkConfirmPassword,
            required: true,
          },
        ]}
      />
      <Button type="link" onClick={() => handleFormChange('signIn')}>
        Go Back
      </Button>
    </>
  );
};

const ForgotPassword = () => (
  <>
    <InputField name="email" required label="Email" span={12} />
  </>
);

const AuthModal = () => {
  const [currentState, setCurrentState] = useState<string>('signIn');
  const [form] = Form.useForm();

  const authState = useSelector((state: RootState) => state.Auth);

  const take = (path: string) => get(authState, path);

  const dispatch = useDispatch();
  const handleCancel = () => {
    dispatch(closeModal(AUTH_MODAL_ID));
    setCurrentState('signIn');
  };

  const handleFormChange = (type: string) => setCurrentState(type);

  const handleFormSubmit = (values: Record<string, string>) => {
    if (currentState === 'signIn')
      dispatch(loginUser({ username: values.username.toLowerCase(), password: values.password }));
    if (currentState === 'signUp') {
      const { email, name, password } = values;
      dispatch(createUser({ email: email.toLowerCase(), name, password }));
    }
  };

  const isLoading = take('isLoading');
  return (
    <Form
      layout="vertical"
      onFinish={handleFormSubmit}
      name="auth"
      form={form}
      onChange={() => form.validateFields()}
      validateTrigger="onChange"
    >
      <APNModal
        id={AUTH_MODAL_ID}
        onCancel={handleCancel}
        title={TITLE_MAPPER[currentState]}
        destroyOnClose
        footer={[
          <Button key="close" onClick={handleCancel}>
            Close
          </Button>,
          <Button key="submit" type="primary" onClick={() => form.submit()} loading={isLoading}>
            {TITLE_MAPPER[currentState]}
          </Button>,
        ]}
      >
        <Row gutter={12}>
          {currentState === 'signIn' && <SignIn handleFormChange={handleFormChange} />}
          {currentState === 'signUp' && (
            <CreateAccount handleFormChange={() => handleFormChange('signIn')} getFieldValue={form.getFieldValue} />
          )}
          {currentState === 'forgotPassword' && <ForgotPassword />}
        </Row>
      </APNModal>
    </Form>
  );
};

export default AuthModal;
