import { IRoute } from '../../common/types/Types';
import AppLayout from '../../layouts/AppLayout';
import Search from './Search';

export const SearchRoute: IRoute = {
  path: '/search/:partNumber?',
  component: Search,
  layout: AppLayout,
  title: 'Search',
};
