import React from 'react';

// import styles from './style.module.scss';

type Props = {
  backgroundColor: string;
  children: React.ReactNode;
};

const BannerComponent = ({ backgroundColor, children }: Props) => {
  return <div style={{ background: backgroundColor, padding: '3vh' }}>{children}</div>;
};

export default BannerComponent;
