import React, { useEffect, useState } from 'react';
import { Table, Row, notification, Divider, Col } from 'antd';
import { get } from 'lodash';

const { Column, ColumnGroup } = Table;

import { secureApi } from '../../../api/Axios';
import { TableRecordType } from '../../../common/types/Types';
import MobileEmptyAndLoader from '../../../components/MobileEmptyAndLoader';
import styles from '../style.module.scss';
import Currency from '../../../components/Currency';

type Props = {
  partNumber: string[];
};

const RepairStations = ({ partNumber }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState<Array<Record<string, string>>>([]);
  useEffect(() => {
    if (partNumber.length > 0) {
      setIsLoading(true);
      secureApi
        .get(`/searchResults?searchBy=${partNumber}`)
        .then((response) => {
          // handle success
          const message = get(response, 'data.message') || false;
          if (message) {
            notification.error({ message });
          } else {
            setDataSource(get(response, 'data.result') || []);
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setDataSource([]);
    }
  }, [partNumber]);

  const RenderFacilityLink = (text: string, record: TableRecordType) => {
    const webLink = get(record, 'repairStation.WebLink');
    const name = get(record, 'repairStation.FacilityName');
    return (
      <div>
        {webLink !== '' ? (
          <a href={webLink} target="_blank" rel="noreferrer">
            {name}
          </a>
        ) : (
          text
        )}
      </div>
    );
  };

  const RenderPart = (x: string, record: TableRecordType) => <div>{get(record, 'partDetail.PartNumber')}</div>;
  const RenderDesc = (x: string, record: TableRecordType) => <div>{get(record, 'partDetail.Description')}</div>;
  const RenderRepPrice = (x: string, record: TableRecordType) => (
    <div>{get(record, 'repairStation.RepairPrice') || '--'}</div>
  );
  const RenderRepTat = (x: string, record: TableRecordType) => (
    <div>{get(record, 'repairStation.RepairHTat') || '--'}</div>
  );
  const RenderOvh = (x: string, record: TableRecordType) => (
    <div>{get(record, 'repairStation.OverhaulPrice') || '--'}</div>
  );
  const RenderOvhTat = (x: string, record: TableRecordType) => (
    <div>{get(record, 'repairStation.OverhaulTat') || '--'}</div>
  );
  const RenderTestPrice = (x: string, record: TableRecordType) => (
    <div>{get(record, 'repairStation.TestPrice') || '--'}</div>
  );
  const RenderTestTat = (x: string, record: TableRecordType) => (
    <div>{get(record, 'repairStation.TestTat') || '--'}</div>
  );

  const WebView = () => (
    <Table loading={isLoading} dataSource={dataSource} rowKey="FacilityName" style={{ minHeight: '400px' }}>
      <Column title="Part #" dataIndex="partDetail" render={RenderPart} />
      <Column title="Description" dataIndex="partDetail" render={RenderDesc} />
      <Column title="Repair Station" dataIndex="repairStation" render={RenderFacilityLink} />
      <ColumnGroup title="Overhaul">
        <Column title="Price" dataIndex="repairStation" render={RenderOvh} />
        <Column title="TAT" dataIndex="repairStation" render={RenderOvhTat} />
      </ColumnGroup>
      <ColumnGroup title="Repair">
        <Column title="Price" dataIndex="repairStation" render={RenderRepPrice} />
        <Column title="TAT" dataIndex="repairStation" render={RenderRepTat} />
      </ColumnGroup>
      <ColumnGroup title="Test">
        <Column title="Price" dataIndex="TestPrice" render={RenderTestPrice} />
        <Column title="TAT" dataIndex="TestTat" render={RenderTestTat} />
      </ColumnGroup>
    </Table>
  );

  const MobileView = () => (
    <div className={styles.mobileContainer}>
      <MobileEmptyAndLoader isLoading={isLoading} rowsLength={dataSource.length} />
      {dataSource.map((item, index) => (
        <Row style={{ padding: 10 }} key={`repair-station-item-${index}`}>
          <Col span={12}>
            <span className={styles.serialNumber}>A100</span> - Description
          </Col>
          <Col style={{ textAlign: 'right' }} span={12}>
            {RenderFacilityLink(get(item, 'repairStation.FacilityName'), {
              repairStation: {
                WebLink: get(item, 'repairStation.WebLink'),
                FacilityName: get(item, 'repairStation.FacilityName'),
              },
            })}
          </Col>
          <Col span={24} style={{ marginTop: 10 }}>
            <Row>
              <Col span={12}>
                <Row>
                  <Col span={24}>Overhaul</Col>
                  <Col span={12}>
                    Price: <Currency money={{ amount: get(item, 'repairStation.OverhaulPrice') }} />
                  </Col>
                  <Col span={12}>Tat: {get(item, 'repairStation.OverhaulTat', '-')}</Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row>
                  <Col span={24}>Repair</Col>
                  <Col span={12}>
                    Price: <Currency money={{ amount: get(item, 'repairStation.RepairPrice') }} />
                  </Col>
                  <Col span={12}>Tat: {get(item, 'repairStation.RepairHTat', '-')}</Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row>
                  <Col span={24}>Test</Col>
                  <Col span={12}>
                    Price: <Currency money={{ amount: get(item, 'repairStation.TestPrice') }} />
                  </Col>
                  <Col span={12}>Tat: {get(item, 'repairStation.TestTat', '-')}</Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Divider />
        </Row>
      ))}
    </div>
  );

  const isMobile = window.innerWidth <= 768;
  return isMobile ? <MobileView /> : <WebView />;
};

export default RepairStations;
