import { all, spawn, call } from 'redux-saga/effects';

import { AuthSagas } from '../Auth';
import { DTSagas } from '../DataTable';
import { GlobalSagas } from '../Global';
import { SearchSagas } from '../Search';

export default function* RootSaga() {
  const sagas = [AuthSagas, DTSagas, GlobalSagas, SearchSagas];
  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (error) {
            console.log(error);
          }
        }
      }),
    ),
  );
}
