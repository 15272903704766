import React from 'react';
import { Route } from 'react-router-dom';

import { IRoute } from '../types/Types';

type RouteWrapper = IRoute;

const PublicRouteWrapper = ({ layout: Layout, component: Component, title, ...props }: RouteWrapper) => {
  return (
    <Route
      {...props}
      render={(matchProps) => (
        <Layout title={title}>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
};

export default PublicRouteWrapper;
