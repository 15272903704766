import React, { ReactElement } from 'react';
import { Drawer } from 'antd';
import { useSelector } from 'react-redux';
import { DrawerProps } from 'antd/es/drawer';

import { RootState } from '../../redux-modules/store/rootState';
import { GlobalState } from '../../redux-modules/Global/Types';
interface PropsModal extends DrawerProps {
  id: string;
  children: ReactElement;
}
const APNDrawer = ({ id, onClose, children, ...props }: PropsModal) => {
  const globalState: GlobalState = useSelector((state: RootState) => state.Global);
  const { drawers } = globalState;
  const isVisible = drawers.includes(id);
  return (
    <Drawer visible={isVisible} onClose={onClose} {...props} destroyOnClose>
      {children}
    </Drawer>
  );
};
export default APNDrawer;
