import React, { ReactElement } from 'react';
import { Form, Input, InputNumber, DatePicker, Col, Select } from 'antd';
import { FormItemProps } from 'antd/es/form';
import { Moment } from 'moment';

const { Option } = Select;

type SelectOption = {
  value: string;
  text: string;
};

interface ItemProps extends FormItemProps {
  span?: number;
  rows?: number;
  isPasswordField?: boolean;
  children?: ReactElement;
  forgotPasswordAction?: () => void;
  rules?: Array<Record<string, unknown>>;
  required?: boolean;
  type?: string | undefined;
  options?: Array<SelectOption>;
  label?: string;
  disabled?: boolean;
  disabledDate?: (currentDate: Moment) => boolean;
}

const InputField = ({ span = 6, required = false, rules, disabled = false, ...props }: ItemProps) => {
  return (
    <Col sm={span} xs={24}>
      <Form.Item
        {...props}
        labelCol={{ span: 24 }}
        labelAlign="left"
        colon={false}
        rules={rules || [{ required: required, message: `${props.label} is required.` }]}
      >
        <Input placeholder={`Enter ${props.label}`} disabled={disabled} />
      </Form.Item>
    </Col>
  );
};

const PasswordField = ({ span = 6, rules, required = false, ...props }: ItemProps) => {
  return (
    <Col sm={span} xs={24}>
      <Form.Item
        {...props}
        labelCol={{ span: 24 }}
        labelAlign="left"
        colon={false}
        rules={rules || [{ required: required, message: `${props.label} is required.` }]}
      >
        <Input.Password placeholder={`Enter ${props.label}`} />
      </Form.Item>
    </Col>
  );
};

const TextField = ({ span = 6, rows = 4, required = false, ...props }: ItemProps) => {
  return (
    <Col sm={span} xs={24}>
      <Form.Item
        {...props}
        labelCol={{ span: 24 }}
        labelAlign="left"
        colon={false}
        rules={[{ required: required, message: `${props.label} is required.` }]}
      >
        <Input.TextArea rows={rows} placeholder={`Enter ${props.label}`} />
      </Form.Item>
    </Col>
  );
};

const DateField = ({ span = 6, required = false, ...props }: ItemProps) => {
  return (
    <Col sm={span} xs={24}>
      <Form.Item
        {...props}
        labelCol={{ span: 24 }}
        labelAlign="left"
        colon={false}
        rules={[{ required: required, message: `${props.label} is required.` }]}
      >
        <DatePicker style={{ width: '100%' }} disabledDate={props.disabledDate} />
      </Form.Item>
    </Col>
  );
};

const NumberField = ({ span = 6, required = false, ...props }: ItemProps) => {
  return (
    <Col sm={span} xs={24}>
      <Form.Item
        {...props}
        labelCol={{ span: 24 }}
        labelAlign="left"
        colon={false}
        rules={[{ required: required, message: `${props.label} is required.` }]}
      >
        <InputNumber style={{ width: '100%' }} min={1} placeholder={`Enter ${props.label}`} />
      </Form.Item>
    </Col>
  );
};

const SelectField = ({ span = 6, required = false, options = [], ...props }: ItemProps) => {
  return (
    <Col sm={span} xs={24}>
      <Form.Item
        {...props}
        labelCol={{ span: 24 }}
        labelAlign="left"
        colon={false}
        rules={[{ required: required, message: `${props.label} is required.` }]}
      >
        <Select style={{ width: '100%' }} placeholder={`Enter ${props.label}`}>
          {options.map(({ value, text }: SelectOption) => (
            <Option key={value} value={value}>
              {text}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Col>
  );
};

export { InputField, DateField, NumberField, TextField, PasswordField, SelectField };
