import React from 'react';
import { Table } from 'antd';
import { TableProps } from 'antd/es/table';

import { Product } from '../../common/types/Types';

const APNTable = (props: TableProps<Product>) => {
  return (
    <div>
      <Table {...props} />
    </div>
  );
};

export default APNTable;
