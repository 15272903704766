import React, { ReactElement } from 'react';
import { Modal } from 'antd';
import { useSelector } from 'react-redux';
import { ModalProps } from 'antd/es/modal';

import { RootState } from '../../redux-modules/store/rootState';
import { GlobalState } from '../../redux-modules/Global/Types';

interface PropsModal extends ModalProps {
  id: string;
  children: ReactElement;
}

const APNModal = ({ id, onCancel, children, ...props }: PropsModal) => {
  const globalState: GlobalState = useSelector((state: RootState) => state.Global);

  const { modals } = globalState;

  const isVisible = modals.includes(id);
  return (
    <Modal visible={isVisible} onCancel={onCancel} {...props} destroyOnClose>
      {children}
    </Modal>
  );
};

export default APNModal;
