import { Network, Product } from '../../common/types/Types';
import { ModalIds, DrawerIds } from './Types';

export const REMOVE_ITEM_FROM_CART = 'global/REMOVE_ITEM_FROM_CART';
export const UPDATE_CART = 'global/UPDATE-CART';
export const ADD_CART_ITEM = 'global/ADD_CART_ITEM';
export const SET_CART_LOADING = 'global/SET_CART_LOADING';
export const SET_CART_LOADING_COMPLETE = 'global/SET_CART_LOADING_COMPLETE';
export const OPEN_MODAL = 'global/OPEN-MODAL';
export const CLOSE_MODAL = 'global/CLOSE-MODAL';
export const UPDATE_MODAL_LIST = 'global/UPDATE-MODAL-LIST';
export const CLOSE_ALL_MODALS = 'globals/CLOSE-ALL-MODALS';
export const CREATE_SRFQ = 'globals/CREATE_SRFQ';
export const CREATE_SRFQ_COMPLETE = 'globals/CREATE-SRFQ-COMPLETE';
export const LOAD_GLOBAL_DATA = 'globals/LOAD-GLOBAL-DATA';
export const GET_NETWORK_INFO = 'globals/GET-NETWORK-INFO';
export const UPDATE_NETWORK_INFO = 'globals/UPDATE-NETWORK-INFO';
export const OPEN_DRAWER = 'global/OPEN-DRAWER';
export const CLOSE_DRAWER = 'global/CLOSE-DRAWER';
export const UPDATE_DRAWER_LIST = 'global/UPDATE_DRAWER_LIST';
export const CLOSE_ALL_DRAWERS = 'globals/CLOSE-ALL-DRAWERS';

export const addItemInCart = (item: Product) => {
  return {
    type: ADD_CART_ITEM,
    payload: item,
  };
};

export const createSRFQ = (payload: any) => {
  return {
    type: CREATE_SRFQ,
    payload,
  };
};

export const removeItemFromCart = (record: Product) => {
  return {
    type: REMOVE_ITEM_FROM_CART,
    payload: record,
  };
};

export const updateCart = (payload: Array<Product>) => ({ type: UPDATE_CART, payload });
export const setCartLoading = () => ({ type: SET_CART_LOADING });
export const setCartLoadingComplete = () => ({ type: SET_CART_LOADING_COMPLETE });
export const openModal = (payload: string) => ({ type: OPEN_MODAL, payload });
export const closeModal = (payload: string) => ({ type: CLOSE_MODAL, payload });
export const updateModalList = (payload: ModalIds) => ({ type: UPDATE_MODAL_LIST, payload });
export const createSRFQComplete = () => ({ type: CREATE_SRFQ_COMPLETE });
export const loadGlobalData = () => ({ type: LOAD_GLOBAL_DATA });
export const getNetworkInfo = () => ({ type: GET_NETWORK_INFO });
export const updateNetwork = (payload: Network) => ({ type: UPDATE_NETWORK_INFO, payload });
export const openDrawer = (payload: string) => ({ type: OPEN_DRAWER, payload });
export const closeDrawer = (payload: string) => ({ type: CLOSE_DRAWER, payload });
export const updateDrawerList = (payload: DrawerIds) => ({ type: UPDATE_DRAWER_LIST, payload });
