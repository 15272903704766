import React, { ReactElement } from 'react';

import styles from './style.module.scss';

type Props = {
  children: ReactElement;
  backgroundImage: string;
};

const HeroBanner = ({ children, backgroundImage }: Props) => {
  return (
    <div style={{ backgroundImage: `url(${backgroundImage})` }} className={styles.heroBannerStyle}>
      <div className={styles.heroBannerChildStyle}>{children}</div>
    </div>
  );
};

export default HeroBanner;
