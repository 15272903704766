import React, { FC } from 'react';
import { Button, Form, Input, Card } from 'antd';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { loginUser } from '../../redux-modules/Auth/Actions';
import { LoginParams } from '../../redux-modules/Auth/Types';
import { RootState } from '../../redux-modules/store/rootState';

const SignIn: FC = () => {
  const dispatch = useDispatch();
  const authState = useSelector((state: RootState) => state.Auth);

  const take = (path: string) => get(authState, path);

  const onFinish = (values: LoginParams) => {
    dispatch(loginUser(values));
  };

  return (
    <div style={{ paddingTop: 200 }}>
      <Card style={{ width: 300, margin: 'auto' }}>
        <h3>Log In</h3>
        <Form name="normal_login" className="login-form" initialValues={{ remember: true }} onFinish={onFinish}>
          <Form.Item name="username" rules={[{ required: true, message: 'Please input your Username!' }]}>
            <Input placeholder="Username" />
          </Form.Item>
          <Form.Item name="password" rules={[{ required: true, message: 'Please input your Password!' }]}>
            <Input.Password type="password" placeholder="Password" />
          </Form.Item>
          <Form.Item>
            <Button loading={take('isLoading')} type="primary" htmlType="submit" className="login-form-button">
              Log in
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default SignIn;
