import React, { useState } from 'react';
import { Layout, Menu } from 'antd';

import styles from './Footer.module.css';
import { apnFooter, whiteApnLogo } from '../../assets';

type MenuProps = {
  label: string;
  path: string;
  isSubMenu?: boolean;
  subMenuItem?: Array<MenuProps>;
};

const menuItems: Array<MenuProps> = [
  {
    label: 'SOLUTION',
    path: '/search',
    isSubMenu: true,
    subMenuItem: [
      {
        label: 'APN SYNC',
        path: 'https://aeropartsnow.com/solutions/apn-sync',
      },
      {
        label: 'APN RFQ',
        path: 'https://aeropartsnow.com/solutions/apn-rfq',
      },
      {
        label: 'APN STOREFRONT',
        path: 'https://aeropartsnow.com/solutions/apn-storefront',
      },
      {
        label: 'APN FEATURES',
        path: 'https://aeropartsnow.com/solutions/smart-features',
      },
    ],
  },
  {
    label: 'NEWS',
    path: 'https://aeropartsnow.com/news',
  },
  {
    label: 'ABOUT',
    path: 'https://aeropartsnow.com/about',
  },
  {
    label: 'CUSTOMERS',
    path: 'https://aeropartsnow.com/customers',
  },
  {
    label: 'CAREERS',
    path: 'https://aeropartsnow.com/careers',
  },
  {
    label: 'CONTACT',
    path: 'https://aeropartsnow.com/contact',
  },
];

const Footer = () => {
  const [isMobile] = useState(window.innerWidth <= 768);

  return (
    <Layout.Footer style={{ padding: 0, margin: 0 }} className={styles.container}>
      <>
        <div
          style={{
            width: '100%',
            height: 'auto',
            textAlign: 'center',
            fontSize: 20,
            color: 'white',
            backgroundImage: `url(${apnFooter})`,
            padding: 15,
          }}
        >
          ONE SEARCH FOR MARKETPLACES AND STOREFRONTS
          <p style={{ fontSize: 12 }}>ONLY ON AEROPARTS NOW</p>
        </div>
        <div style={{ background: '#1F3D57', height: '5vh' }} />
        <div style={{ alignContent: 'center', width: '100%', height: 'auto', background: '#1F3D57' }}>
          <div
            style={{
              textAlign: 'center',
              marginLeft: 'auto',
              marginRight: 'auto',
              width: 'fit-content',
            }}
          >
            <Menu
              mode={isMobile ? 'vertical' : 'horizontal'}
              theme="dark"
              style={{ background: '#1F3D57' }}
              selectedKeys={[]}
            >
              {menuItems.map(({ label, path, isSubMenu, subMenuItem }: MenuProps) =>
                isSubMenu ? (
                  <Menu.SubMenu
                    key={label}
                    style={{ fontSize: 14, color: 'white', fontWeight: 'bold', textAlign: 'center' }}
                    title={
                      <span>
                        <span>{label}</span>
                      </span>
                    }
                  >
                    {subMenuItem &&
                      subMenuItem.map(({ label, path }) => (
                        <Menu.Item
                          key={label}
                          onClick={() => window.open(path, '_blank')}
                          style={{
                            fontSize: 10,
                            color: 'white',
                            fontWeight: 'bold',
                            background: '#1F3D57',
                            margin: 0,
                          }}
                        >
                          <span>{label}</span>
                        </Menu.Item>
                      ))}
                  </Menu.SubMenu>
                ) : (
                  <Menu.Item
                    key={label}
                    onClick={() => window.open(path, '_blank')}
                    style={{
                      fontSize: 14,
                      color: 'white',
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}
                  >
                    {label}
                  </Menu.Item>
                ),
              )}
            </Menu>
          </div>
          <div style={{ textAlign: 'center', margin: '3vh' }}>
            <img alt="APNSync" src={whiteApnLogo} className={styles.logo} />
          </div>
          <div style={{ textAlign: 'center', color: 'white', paddingBottom: '4vh' }}>
            THE FUTURE OF AVIATION COMMERCE
          </div>
          <div style={{ textAlign: 'center', color: 'white', background: '#002140', padding: '1.5vh' }}>
            Copyright © 2021 AeroParts Now. All rights reserved.{' '}
            <a href="https://aeropartsnow.com/privacy" target="_blank" rel="noreferrer">
              Privacy
            </a>{' '}
            |{' '}
            <a href="https://aeropartsnow.com/terms-of-service" target="_blank" rel="noreferrer">
              Terms of Service
            </a>
          </div>
        </div>
      </>
    </Layout.Footer>
  );
};

export default Footer;
