import React from 'react';

type Props = {
  label: string;
};

const LabelHeader = ({ label }: Props) => (
  <div style={{ color: '#595959', fontWeight: 600, fontSize: 14 }}>{label}</div>
);

export default LabelHeader;
