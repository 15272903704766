import { get } from 'lodash';

import { IDataTableProcedure, IDataTableResults, IDTParameters, IDTFilters } from './Types';
import BaseService from '../../../../api/BaseService';
import { getSQLQueryString } from '../../../utils/SQLQueryConverter';

class DataTableProcedure implements IDataTableProcedure {
  private readonly type: string;
  constructor(type: string) {
    this.type = type;
  }
  search = async (parameters: IDTParameters): Promise<IDataTableResults> => {
    const { query, current, pageSize, networkId = undefined, filters, dataPath = 'networkPartSearch' } = parameters;

    const payload = {
      pageNum: current,
      pageSize,
      networkId,
      query: '',
    };
    const { queryVariable, sortBy, ...restFilter }: IDTFilters = filters || {};
    const queryString = getSQLQueryString(queryVariable, 'totalQuantity > 0');
    const serviceData = {
      query,
      variables: {
        ...payload,
        query: queryString ? queryString : undefined,
        sortBy: JSON.stringify(sortBy),
        filter: JSON.stringify(restFilter),
      },
    };
    const res = this.type === 'Auth' ? await BaseService.auth(serviceData) : await BaseService.public(serviceData);
    const totalCount: number = get(res, `data.${dataPath}.totalCount`) || 0;
    const rows: Array<Record<string, unknown>> = (get(res, `data.${dataPath}.rows`) || []).splice(0, pageSize);
    return {
      totalCount: totalCount,
      rows: rows,
    };
  };
}

export const DTProcedure = new DataTableProcedure('Auth');
export const DTProcedurePublic = new DataTableProcedure('Public');
