import { IRoute } from '../../common/types/Types';
import AppLayout from '../../layouts/AppLayout';
import Sellers from './Sellers';

export const SellersRoute: IRoute = {
  path: '/sellers',
  component: Sellers,
  layout: AppLayout,
  exact: true,
  title: 'Sellers',
};
