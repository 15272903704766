import { get } from 'lodash';

import { SearchState } from './Types';
import { ReduxAction } from '../../common/types/Types';
import { INIT_SEARCH, SEARCH_DATA_COMPLETE, SEARCH_DATA_LOADING } from './Actions';

export default (state: SearchState = {}, action: ReduxAction) => {
  const { type, payload } = action;
  const context = get(payload, 'context');
  switch (type) {
    case INIT_SEARCH: {
      return {
        ...state,
        [context]: {
          ...payload,
        },
      };
    }
    case SEARCH_DATA_LOADING:
      return {
        ...state,
        [context]: {
          ...state[context],
          isLoading: true,
        },
      };
    case SEARCH_DATA_COMPLETE:
      return {
        ...state,
        [context]: {
          ...state[context],
          isLoading: false,
          searchedKeywords: payload.searchedResults,
        },
      };
    default:
      return state;
  }
};
