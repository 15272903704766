import React, { FC, useEffect, useState } from 'react';
import { Spin, Alert, Button, Card } from 'antd';
import { useParams } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useDispatch } from 'react-redux';

import { openModal } from '../../redux-modules/Global/Actions';
import history from '../../common/utils/history';
import { AUTH_MODAL_ID } from '../../common/utils/constants';

type ParamsType = {
  username: string;
  code: string;
};
const Verify: FC = () => {
  const dispatch = useDispatch();
  const { username, code } = useParams<ParamsType>();
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState({
    success: false,
    failed: false,
    message: '',
  });
  useEffect(() => {
    if (code && username) {
      Auth.confirmSignUp(username, code)
        .then(() => {
          setStatus({
            success: true,
            failed: false,
            message: 'User verified',
          });
          setIsLoading(false);
        })
        .catch((error) => {
          if (error) {
            setIsLoading(false);
            setStatus({
              success: false,
              failed: false,
              message:
                error.code === 'UserNotFoundException' ? 'User not found, please contact support' : error.message,
            });
          }
        });
    }
  }, [username, code]);

  const onSubmit = () => {
    history.push('/history');
    dispatch(openModal(AUTH_MODAL_ID));
  };

  return (
    <div style={{ paddingTop: 200 }}>
      <Card
        style={{
          width: 400,
          minHeight: 300,
          margin: 'auto',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isLoading && <Spin tip="Verifying ..." />}
        {!isLoading && (
          <>
            <Alert type={status.success ? 'success' : 'error'} message={status.message} />
            <Button onClick={onSubmit} type="link" style={{ marginTop: 100 }}>
              Click here to login
            </Button>
          </>
        )}
      </Card>
    </div>
  );
};
export default Verify;
