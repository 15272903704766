import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { debounce, get, uniqBy } from 'lodash';
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons';

import { ISearchParameters, ISearchProcedure, SearchResult } from '../../common/libs/procedures/SearchProcedure/Types';
import { initSearch, searchData } from '../../redux-modules/Search/Actions';
import { RootState } from '../../redux-modules/store/rootState';

interface SearchProps extends SelectProps<any> {
  parameters: ISearchParameters;
  context: string;
  procedure: ISearchProcedure;
}

const { Option } = Select;
const iconStyle = { color: '#1F3D57' };
const SearchBox = ({ parameters, context, procedure, mode, placeholder, ...rest }: SearchProps) => {
  const [searchString, setSearchString] = useState('');
  const dispatch = useDispatch();
  const searchStore = useSelector((state: RootState) => state.Search[context]);

  // curry
  const take = (path: string) => get(searchStore, path);

  useEffect(() => {
    dispatch(
      initSearch({
        context,
        parameters,
        procedure,
      }),
    );
  }, []);

  const handleChange = (searchString: string) => {
    setSearchString(searchString);
    dispatch(
      searchData({
        context,
        parameters: {
          ...parameters,
          searchString,
        },
      }),
    );
  };

  const debounceLoadData = useCallback(debounce(handleChange, 500), []);

  const searchedKeywords = take('searchedKeywords') || [];
  const isLoading = take('isLoading');

  const uniqSearchedItems = uniqBy(searchedKeywords, (item: SearchResult) => item.partNumber);
  return (
    <div>
      <Select
        mode={mode}
        style={{ width: '100%' }}
        placeholder={placeholder || 'Search Here'}
        onSearch={debounceLoadData}
        showSearch
        size="large"
        loading={isLoading}
        notFoundContent={searchString.length >= 2 && !isLoading && searchedKeywords.length === 0 && 'No parts found.'}
        suffixIcon={isLoading ? <LoadingOutlined style={iconStyle} /> : <SearchOutlined style={iconStyle} />}
        {...rest}
      >
        {searchedKeywords.length > 0 &&
          uniqSearchedItems.map(({ partNumber, id }: SearchResult) => (
            <Option key={id} value={partNumber}>
              {partNumber}
            </Option>
          ))}
      </Select>
    </div>
  );
};

export default SearchBox;
