import React from 'react';
import { Form, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

import APNModal from '../Modals';
import { PROFILE_MODAL_ID } from '../../common/utils/constants';
import { InputField } from '../Form/FormFields';
import { closeModal } from '../../redux-modules/Global/Actions';
import { RootState } from '../../redux-modules/store/rootState';
import { splitName } from '../../common/utils/helpers';

// type Props = {
//   // eslint-disable-next-line no-unused-vars
//   handleFormChange: (state: string) => void;
//   // eslint-disable-next-line no-unused-vars
//   getFieldValue?: any;
// };

const Profile = () => {
  const [form] = Form.useForm();

  const authState = useSelector((state: RootState) => state.Auth);

  const take = (path: string) => get(authState, path);

  const dispatch = useDispatch();
  const handleCancel = () => {
    dispatch(closeModal(PROFILE_MODAL_ID));
  };

  const handleFormSubmit = (values: Record<string, string>) => {
    console.log(values, 'values');
  };

  // const isLoading = take('isLoading');
  const user = take('user');
  const userName = splitName(user.name).split(' ');
  console.log(userName);
  return (
    <Form
      layout="vertical"
      onFinish={handleFormSubmit}
      name="auth"
      form={form}
      onChange={() => form.validateFields()}
      validateTrigger="onChange"
    >
      <APNModal
        id={PROFILE_MODAL_ID}
        onCancel={handleCancel}
        title="Profile"
        destroyOnClose
        footer={null}
        // footer={[
        //   <Button key="close" onClick={handleCancel}>
        //     Close
        //   </Button>,
        //   <Button key="submit" type="primary" onClick={() => form.submit()} loading={isLoading}>
        //     Update
        //   </Button>,
        // ]}
      >
        <Row gutter={12}>
          <InputField name="firstName" label="First Name" required span={12} initialValue={userName[0]} />
          <InputField name="lastName" label="Last Name" required span={12} initialValue={userName[1]} />
          <InputField name="email" label="Email" required span={12} initialValue={take('user.email')} disabled />
          <InputField name="phone" label="Phone" span={12} />
        </Row>
      </APNModal>
    </Form>
  );
};

export default Profile;
