import { GRAPHQL_AUTH_MODE, GraphQLOptions } from '@aws-amplify/api-graphql';
import { API } from 'aws-amplify';

export default {
  auth: ({ query, variables }: GraphQLOptions) =>
    API.graphql({
      query,
      variables,
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    }),
  public: ({ query, variables }: GraphQLOptions) =>
    API.graphql({
      query,
      variables,
      authMode: GRAPHQL_AUTH_MODE.API_KEY,
    }),
};
