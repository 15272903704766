import React, { ReactElement, useEffect, useState } from 'react';
import { Auth } from '@aws-amplify/auth';
import { Spin } from 'antd';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';

import { loginComplete } from '../../redux-modules/Auth/Actions';

type Props = {
  children: ReactElement;
};

const AuthWrapper = ({ children }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        dispatch(loginComplete(get(user, 'attributes')));
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error) // eslint-disable-line
        setIsLoading(false);
      });
  }, []);
  return isLoading ? <Spin /> : children;
};

export default AuthWrapper;
