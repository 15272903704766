import React from 'react';
import { Button, Col, Row } from 'antd';

import HeroBanner from '../../components/Banners/HeroBanner';
import VideoCard from '../../components/VideoCard';
import { APNBenefits, Banner, BannerTextData, WhyWeAreDifferentBanner } from '../../components/Banners';
import styles from '../buyers/styles.module.scss';
import {
  branding,
  manageAccess,
  multipleStoreFront,
  socialDetails,
  storeFrontBackground,
  storeFrontHeroBg,
  syncDetails,
} from '../../assets';
import { Image } from '../../components/DummyHelpers/Image';

const isMobile = window.innerWidth < 768;

const Sellers = () => {
  return (
    <>
      <HeroBanner backgroundImage={storeFrontHeroBg}>
        <Row>
          <Col xs={24} sm={12}>
            <h1 style={{ fontSize: 36, marginBottom: 20, fontWeight: 300 }}>Storefronts</h1>
            <BannerTextData
              header="Start Selling Direct"
              subheader="APN Storefront allows you to set up a custom-branded eCommerce store with a
              live connection to your inventory."
              textTheme="dark"
            />
            <VideoCard youtubeId="Zkb5x-VyaAs" />
            <a href="https://aeropartsnow.com/get-started" target="_blank" rel="noreferrer">
              <Button
                type="primary"
                className={styles.scheduleDemoButton}
                size="large"
                style={{ marginTop: 20, borderRadius: 4 }}
              >
                SCHEDULE DEMO
              </Button>
            </a>
          </Col>
        </Row>
      </HeroBanner>
      <Banner backgroundColor="#53B848">
        <Row className={styles.bannerStyle}>
          <Col sm={12} xs={24} className={styles.bannerColStyle}>
            <BannerTextData
              header="Promote Your Inventory"
              subheader="Reach a new world of potential customers through marketing-optimized web searches."
              textTheme="light"
            />
          </Col>
          <Image image={socialDetails} />
        </Row>
      </Banner>
      <Banner backgroundColor="#F0F2F5">
        <Row className={styles.bannerStyle}>
          {!isMobile && <Image image={branding} />}
          <Col sm={12} xs={24} className={styles.bannerColStyle}>
            <BannerTextData
              header="Promote Your Brand"
              subheader="Use your company logo, colors, branding, messaging and promotions."
              textTheme="dark"
            />
          </Col>
          {isMobile && <Image image={branding} />}
        </Row>
      </Banner>
      <Banner backgroundColor="#53B848">
        <Row className={styles.bannerStyle}>
          <Col sm={12} xs={24} className={styles.bannerColStyle}>
            <BannerTextData
              header="Extend Your Reach"
              subheader="Integrate APN Storefront into your existing website and add new sales channels."
              textTheme="light"
            />
          </Col>
          <Image image={syncDetails} />
        </Row>
      </Banner>
      <Banner backgroundColor="#F0F2F5">
        <Row className={styles.bannerStyle}>
          {!isMobile && <Image image={manageAccess} />}
          <Col sm={12} xs={24} className={styles.bannerColStyle}>
            <BannerTextData
              header="Manage Access"
              subheader="Publish your APN Storefront as a public or private site for your customers."
              textTheme="dark"
            />
          </Col>
          {isMobile && <Image image={manageAccess} />}
        </Row>
      </Banner>
      <Banner backgroundColor="#53B848">
        <Row className={styles.bannerStyle}>
          <Col sm={12} xs={24} className={styles.bannerColStyle}>
            <BannerTextData
              header="Launch Multiple Storefronts"
              subheader="Engineered with growth and scalability in mind, APN Storefront gives you the flexibility to have multiple sites that can be targeted for different audiences, highlight product lines or even aircraft types—all managed in one place."
              textTheme="light"
            />
          </Col>
          <Image image={multipleStoreFront} />
        </Row>
      </Banner>
      <WhyWeAreDifferentBanner
        backgroundImage={storeFrontBackground}
        header="WHY WE’RE DIFFERENT"
        subheader="APN empowers you to accelerate your sales cycle and promote your brand to the world."
        textTheme="dark"
        button={{
          label: 'SCHEDULE DEMO',
          redirectionLink: 'https://aeropartsnow.com/get-started',
          type: 'primary',
        }}
      />
      <APNBenefits />
    </>
  );
};

export default Sellers;
