import React, { ReactElement } from 'react';

import StaticHeader from './StaticHeader';

type Props = {
  children: ReactElement;
};

const StaticLayout = ({ children }: Props) => {
  return (
    <div>
      <StaticHeader />
      {children}
    </div>
  );
};

export default StaticLayout;
