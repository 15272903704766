import React from 'react';
import { Row, Col, Button } from 'antd';
import { Link } from 'react-router-dom';

import HeroBanner from '../../components/Banners/HeroBanner';
import VideoCard from '../../components/VideoCard';
import { APNBenefits, Banner, BannerTextData, WhyWeAreDifferentBanner } from '../../components/Banners';
import { apnCurrency, apnProcurement, apnSearch, buyersHeroBg, quoteBackgroundBuyer } from '../../assets';
import styles from './styles.module.scss';
import { Image } from '../../components/DummyHelpers/Image';

const isMobile = window.innerWidth < 768;

const Buyers = () => (
  <>
    <HeroBanner backgroundImage={buyersHeroBg}>
      <Row>
        <Col xs={24} sm={12}>
          <h1 style={{ fontSize: 36, marginBottom: 20, fontWeight: 300 }}>Buyers</h1>
          <BannerTextData
            header="Ultimate Buying Power"
            subheader="AeroParts Now puts the power of the world’s top procurement networks in your hands."
            textTheme="dark"
          />
          <VideoCard youtubeId="Zkb5x-VyaAs" />
          <Link to="/search">
            <Button
              type="primary"
              className={styles.scheduleDemoButton}
              size="large"
              style={{ marginTop: 20, borderRadius: 4 }}
            >
              SEARCH FREE NOW
            </Button>
          </Link>
        </Col>
      </Row>
    </HeroBanner>
    <Banner backgroundColor="#AF64CB">
      <Row className={styles.bannerStyle}>
        <Col sm={12} xs={24} className={styles.bannerColStyle}>
          <BannerTextData
            header="APN Search"
            subheader="Search the APN Network™ of storefronts and popular marketplaces like PartsBase and ILS."
            textTheme="light"
          />
        </Col>
        <Image image={apnSearch} />
      </Row>
    </Banner>
    <Banner backgroundColor="#F0F2F5">
      <Row className={styles.bannerStyle}>
        {!isMobile && <Image image={apnProcurement} />}
        <Col sm={12} xs={24} className={styles.bannerColStyle}>
          <BannerTextData
            header="Integrated Purchasing"
            subheader="Manage the entire procurement lifecycle without leaving the APN platform. Create and send supplier
            RFQs in seconds. APN makes it easy to submit requests-for-quote to all of your preferred suppliers in just a few steps."
            textTheme="dark"
          />
        </Col>
        {isMobile && <Image image={apnProcurement} />}
      </Row>
    </Banner>
    <Banner backgroundColor="#AF64CB">
      <Row className={styles.bannerStyle}>
        <Col sm={12} xs={24} className={styles.bannerColStyle}>
          <BannerTextData
            header="Multi-Currency Enabled"
            subheader="APN Search allows you combine all of your procurement searches into a single place, for free."
            textTheme="light"
          />
        </Col>
        <Image image={apnCurrency} />
      </Row>
    </Banner>
    <WhyWeAreDifferentBanner
      backgroundImage={quoteBackgroundBuyer}
      header="WHY WE’RE DIFFERENT"
      subheader="APN Search allows you combine all of your procurement searches into a single place, for free."
      textTheme="dark"
      button={{
        label: 'SCHEDULE DEMO',
        redirectionLink: 'https://aeropartsnow.com/get-started',
        type: 'primary',
      }}
    />
    <APNBenefits />
  </>
);

export default Buyers;
