export const socialMedia = `
   socialMedia{
    twitter
    facebook
    instagram
    youtube
    linkedin
}`;

export const companyHeader = `
    header{
        alignLogo
        logoMaxWidth
        headline
        subheadline
    }
`;

export const color = `
    color{
        primaryColor
        secondaryColor
        buttonColor
        buttonTextColor
        textFieldTextColor
        textFieldBorderColor
        textFieldColor
        smallButtonTextBorderColor
        imageOverlayTextColor
        imageOverlayColor
        imageOverlayOpacity
        bordersColor
        bodyBgColor
        footerBackgroundColor
        footerTextColor
        footerSocialLinkColor
        headerBackgroundColor
        headerTextColor
    }
`;

export const logos = `
    logos{
     primary
     light
    }
`;

export const footer = `
    footer {
        socialLinks
        address
}
`;

export const hero = `
    hero{
    alignment
    heroSize
    textSize
}
`;
