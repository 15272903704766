import { connectRouter } from 'connected-react-router';
import { LocationState, History } from 'history';
import { combineReducers } from 'redux';

import { AuthReducer } from '../Auth';
import { DTReducer } from '../DataTable';
import { GlobalReducer } from '../Global';
import { SearchReducer } from '../Search';

// create a root reducer
const rootReducer = (history: History<LocationState>) =>
  combineReducers({
    // all reducers here
    Auth: AuthReducer,
    DataTable: DTReducer,
    Global: GlobalReducer,
    Search: SearchReducer,
    // for connected router
    router: connectRouter(history),
  });

export default rootReducer;
