import React from 'react';

import styles from './style.module.scss';

type Props = {
  header: string;
  subheader: string;
  description?: string;
  textTheme: 'dark' | 'light';
};

const BannerTexts = ({ header, subheader, description, textTheme }: Props) => {
  return (
    <div className={textTheme === 'dark' ? styles.dark : styles.light}>
      <h1>{header}</h1>
      <h4>{subheader}</h4>
      <p>{description}</p>
    </div>
  );
};

export default BannerTexts;
