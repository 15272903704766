import React from 'react';
import { Empty, Spin } from 'antd';

import styles from './style.module.scss';

type Props = {
  isLoading: boolean;
  rowsLength: number;
};

const MobileEmptyAndLoader = ({ isLoading, rowsLength }: Props) => {
  return (
    <>
      {isLoading && (
        <div className={styles.loaderAndEmptyState}>
          <Spin style={{ marginTop: 40 }} />
        </div>
      )}
      {!isLoading && rowsLength === 0 && <Empty description="No Data Found" />}
    </>
  );
};

export default MobileEmptyAndLoader;
