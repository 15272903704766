import React, { FC, useEffect } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { useDispatch } from 'react-redux';

import './App.css';
import Routes from './Routes';
import history from './common/utils/history';
import AuthWrapper from './components/AuthWrapper/AuthWrapper';
import { loadGlobalData } from './redux-modules/Global/Actions';

const App: FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadGlobalData());
  }, []);
  return (
    <div className="App">
      <ConnectedRouter history={history}>
        <AuthWrapper>
          <Routes />
        </AuthWrapper>
      </ConnectedRouter>
    </div>
  );
};

export default App;
