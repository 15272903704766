import { takeLatest, put, select, call } from 'redux-saga/effects';
import { get } from 'lodash';

import {
  FETCH_DATA,
  fetchDataComplete,
  SET_DATA_TABLE_FILTERS,
  setDataTableLoading,
  stopDataLoading,
  updateDataTableFilters,
} from './Actions';
import { ISagaAction } from '../Types';
import { IDataTableResults, ISearchParameters } from '../../common/libs/procedures/DataTableProcedure/Types';
import { DataTableState } from './Types';

function* fetchData({ payload }: ISagaAction<ISearchParameters>) {
  const { context } = payload;
  yield put(setDataTableLoading({ context }));
  const dataTable: ISearchParameters = yield select((state) => state.DataTable[context]);
  const { parameters, procedure } = dataTable;
  try {
    const response: IDataTableResults = yield call(() => procedure.search(parameters));
    yield put(
      fetchDataComplete({
        context,
        results: {
          ...response,
          rows: response.rows,
        },
      }),
    );
  } catch (error) {
    yield put(stopDataLoading({ context }));
  }
}

function* updateFilterSaga({ payload }: ISagaAction<any>) {
  const { context, filters, ...others } = payload;
  const dataTableState: DataTableState = yield select((state) => state.DataTable[context]);

  // const combinedFilters = {
  //   ...get(dataTableState, 'parameters.filters', {}),
  //   ...filters,
  // };

  // const isFilterAvl =
  //   get(combinedFilters, 'partNumber', []).length > 0 || get(combinedFilters, 'conditionCodes', []).length > 0;

  const newParams = {
    context,
    parameters: {
      ...get(dataTableState, 'parameters', {}),
      ...others,
      filters: {
        ...get(dataTableState, 'parameters.filters', {}),
        ...filters,
      },
    },
  };
  yield put(updateDataTableFilters(newParams));
}

export default function* dataTableSagas() {
  yield takeLatest(FETCH_DATA, fetchData);
  yield takeLatest(SET_DATA_TABLE_FILTERS, updateFilterSaga);
}
