import React from 'react';
import { Amplify } from 'aws-amplify';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';
import { amplify } from './config/amplify';
import { configStore } from './redux-modules/store';
import reportWebVitals from './reportWebVitals';
import './index.css';

const { store } = configStore();

Amplify.configure(amplify);

Amplify.configure({
  Analytics: {
    // OPTIONAL - disable Analytics if true
    disabled: false,
    // OPTIONAL - Allow recording session events. Default is true.
    autoSessionRecord: true,

    AWSPinpoint: {
      // OPTIONAL -  Amazon Pinpoint App Client ID
      // eslint-disable-next-line no-undef
      appId: process.env.REACT_APP_PINPOINT_APP_ID,
      // OPTIONAL -  Amazon service region
      // eslint-disable-next-line no-undef
      region: process.env.REACT_APP_AWS_PROJECT_REGION,
    },
  },
});
console.log('version, uat 1.1');
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
