import React, { useEffect, useState } from 'react';
import { get, minBy, maxBy } from 'lodash';
import { Button, Row, Col, Tooltip, Divider } from 'antd';
import {
  CameraOutlined,
  FileDoneOutlined,
  ShoppingCartOutlined,
  PlusSquareTwoTone,
  MinusSquareTwoTone,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { ColumnsType } from 'antd/es/table';

import { DTProcedurePublic } from '../../../common/libs/procedures/DataTableProcedure/DataTableProcedure';
import FormattedDate from '../../../components/FormattedDate';
import DataTable from '../../../components/DataTable';
import { IDTParameters } from '../../../common/libs/procedures/DataTableProcedure/Types';
import { networkPartSearch } from '../../../queries/company';
import {
  CCItems,
  CompanyInfo,
  CompanyProductSummary,
  // Locations,
  Media,
  Money,
  Network,
  TableRecordType,
} from '../../../common/types/Types';
// import { LocationBlock } from '../../../components/DummyHelpers';
import { DOC_MEDIA_ID, MEDIA_IMAGES_ID, networkId, VIEW_STOCK_DRAWER_ID } from '../../../common/utils/constants';
import { DTActions } from '../../../redux-modules/DataTable';
import APNModal from '../../../components/Modals';
import { closeDrawer, closeModal, openDrawer, openModal } from '../../../redux-modules/Global/Actions';
import styles from '../style.module.scss';
import APNTable from '../../../components/APNTable';
import { convertQtyAndCCIntoRows } from '../../../common/utils/helpers';
import { RootState } from '../../../redux-modules/store/rootState';
import { imageIcon } from '../../../assets';
import LabelHeader from '../../../components/DummyHelpers/LabelHeader';
import APNDrawer from '../../../components/APNDrawer';
import Currency from '../../../components/Currency';

type PartListDTProps = {
  addItemToCart: (item: TableRecordType) => void;
  partNumbers: Array<string>;
  filters: Record<string, unknown>;
};

const PartListDT = ({ addItemToCart, partNumbers, filters }: PartListDTProps) => {
  const [selectedRow, setSelectedRow] = useState({});
  const [expandedRowKeys, setExpandedRowKeys] = useState<Array<string>>([]);

  const network: Network = useSelector((state: RootState) => state.Global.network);
  const dataTable = useSelector((state: RootState) => state.DataTable);

  const dispatch = useDispatch();
  const parameters: IDTParameters = {
    query: networkPartSearch,
    pageSize: 25,
    current: 1,
    networkId: networkId,
    dataPath: 'marketPlaceNetworkPartSearch',
    filters: {
      ...filters,
      queryVariable: {
        partNumber: partNumbers,
      },
      sortBy: {
        partNumber: 'asc',
      },
    },
  };

  useEffect(() => {
    const partNumbersInFilter: string[] = get(dataTable['search'], 'parameters.filters.queryVariable.partNumber');
    if (partNumbers !== partNumbersInFilter) {
      const initialPage = {
        current: 1,
      };
      dispatch(
        DTActions.setDataTableFilters({
          context: 'search',
          ...initialPage,
          filters: {
            queryVariable: {
              partNumber: partNumbers,
            },
          },
        }),
      );
    }
  }, [partNumbers]);

  const closeModalById = (id: string) => {
    dispatch(closeModal(id));
  };

  const openModalById = (id: string, record: TableRecordType) => {
    dispatch(openModal(id));
    setSelectedRow(record);
  };

  const closeDrawerById = (id: string) => {
    dispatch(closeDrawer(id));
  };

  const openDrawerById = (id: string, record: TableRecordType) => {
    dispatch(openDrawer(id));
    setSelectedRow(record);
  };

  const handleRowExpand = (record: TableRecordType) => {
    const isExpandable = rowExpandable(record);
    if (isExpandable) {
      // if expanded rows keys are already present and same key is in record then close the key
      // which means user is clicking on the same row
      // if expanded rows keys ar empty push the key from record
      // if key is present in array and record key is different then replace the key
      // key here is product id
      let keys: Array<string> = [];
      if (expandedRowKeys.length === 0) keys = [record.id];
      if (expandedRowKeys.length > 0 && expandedRowKeys[0] === record.id) {
        keys = [];
      } else {
        keys = [record.id];
      }
      setExpandedRowKeys([...keys]);
    }
  };

  // const RenderLocation = (locations: Locations) =>
  //   (locations || []).map(({ name, address, id }) => <LocationBlock key={id} name={name} address={address} />);

  const ExpandedRowRender = (record: TableRecordType) => {
    const rows: Array<CCItems> = convertQtyAndCCIntoRows(record);
    return rows.map((item, index) => (
      <Row key={`row-item-${index}`} style={{ marginBottom: 10 }}>
        <Col span={13} />
        <Col span={11} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Col span={1}>{get(item, 'totalQuantity')}</Col>
          <Col span={3}>{get(item, 'cc')}</Col>
          {parseFloat(get(item, 'listPrice.amount')) > 0 ? <Currency money={get(item, 'listPrice')} /> : 'RFQ'}
          <div />
          <div />
          <div>
            {parseFloat(get(item, 'listPrice.amount')) > 0
              ? BuyNowButton(record)
              : AddToCartButton({ ...record, CC: item.cc })}
          </div>
          <div style={{ width: 10 }} />
        </Col>
      </Row>
    ));
  };

  const style = { width: 120 };
  const isMobile = window.innerWidth <= 768;

  const BuyNowButton = (record: TableRecordType) => (
    <a
      href={`https://${get(record, 'companyInfo.storefront.storefrontDomainName')}/products/${get(record, 'id')}`}
      target="_blank"
      rel="noreferrer"
    >
      <Button style={{ color: 'white', backgroundColor: '#52c41a', ...style }} icon={<ShoppingCartOutlined />}>
        Buy Direct
      </Button>
    </a>
  );

  const AddToCartButton = (record: TableRecordType) => (
    <Button style={style} onClick={() => addItemToCart(record)} type="ghost" icon={<ShoppingCartOutlined />}>
      Add to Cart
    </Button>
  );

  // if price is not present and have only one condition code show add to cart
  // if price is present and only one condition code show buy now and handle redirection
  // if we get more then one condition code show view stock
  // in nested row, if we get items with price show buy now
  // else show add to cart

  const actionColumn = (x: string, record: TableRecordType) => {
    const rows: Array<CCItems> = convertQtyAndCCIntoRows(record);
    if (rows.length === 1) {
      const isPriceAvailable = parseFloat(get(rows[0], 'listPrice.amount')) > 0;
      if (isPriceAvailable) return BuyNowButton(record);
      if (!isPriceAvailable) return AddToCartButton({ ...record, CC: rows.length > 1 ? rows[0].cc : 'NE' });
    }

    if (rows.length > 1) {
      return (
        <Button
          style={style}
          type="ghost"
          icon={<ShoppingCartOutlined />}
          onClick={() => isMobile && openDrawerById(VIEW_STOCK_DRAWER_ID, record)}
        >
          View Stock
        </Button>
      );
    }
  };

  const RenderViewStockList = (record: TableRecordType) => {
    const rows: Array<CCItems> = convertQtyAndCCIntoRows(record);
    return rows.map((item, index) => (
      <Row key={`row-item-${index}`}>
        <Col span={6} style={{ textAlign: 'center' }}>
          <span className={styles.ccBox}>{get(item, 'cc')}</span>
        </Col>
        <Col span={9}>
          <div>
            <span>Price: </span>
            {parseFloat(item.listPrice.amount.toString()) > 0 ? <Currency money={get(item, 'listPrice')} /> : 'RFQ'}
          </div>
        </Col>
        <Col span={9}>
          <div>
            {parseFloat(item.listPrice.amount.toString()) > 0
              ? BuyNowButton(record)
              : AddToCartButton({ ...record, CC: item.cc })}
          </div>
        </Col>
        <Divider />
      </Row>
    ));
  };

  const RenderCC = (x: string, record: TableRecordType) => {
    const rows: CCItems[] = convertQtyAndCCIntoRows(record);
    const allCC = rows.map((item) => item.cc);
    return allCC.join(', ');
  };

  const rowExpandable = (record: TableRecordType): boolean => {
    const rows: CCItems[] = convertQtyAndCCIntoRows(record);
    return rows.length > 1;
  };

  const RenderCompanyName = (company: CompanyInfo) => get(company, 'companyName');

  const RenderDocName = (x: string, record: TableRecordType) => (
    <a href={get(record, 'src')} target="_blank" rel="noreferrer">
      {get(record, 'fileName')}
    </a>
  );

  const RenderDocAndMedia = (x: string, record: TableRecordType) => {
    const row = record as CompanyProductSummary;
    const photo = () => (
      <Tooltip title="Photo">
        <Button
          type="primary"
          shape="circle"
          icon={<CameraOutlined />}
          onClick={() => openModalById(MEDIA_IMAGES_ID, record)}
        />
      </Tooltip>
    );

    const doc = () => (
      <Tooltip title="File">
        <Button
          type="primary"
          shape="circle"
          icon={<FileDoneOutlined />}
          onClick={() => openModalById(DOC_MEDIA_ID, record)}
        />
      </Tooltip>
    );
    if (row) {
      const { mediaImages, mediaDocuments } = row;
      return (
        <>
          <div
            style={{ width: 70, display: 'flex', alignItems: 'center', justifyContent: 'space-between', zIndex: 1000 }}
          >
            {mediaDocuments && mediaDocuments.length ? doc() : null}
            {mediaImages && mediaImages.length ? photo() : null}
          </div>
        </>
      );
    }
  };

  const companyFilters = network.companyList.map((item) => ({ text: item.name, value: item.id }));

  const RenderPrice = (price: string, record: TableRecordType) => {
    const priceByCCString = get(record, 'qtyAndPriceByCC');
    let priceByCC: Record<string, string> = {};
    try {
      if (priceByCCString) {
        priceByCC = JSON.parse(priceByCCString);
      }

      const arrayOfCC = (Object.keys(priceByCC) || []).reduce((acc: Array<Money>, cur: string) => {
        acc.push({
          amount: get(priceByCC[cur], 'listPrice'),
          currencyCode: get(priceByCC[cur], 'priceInfo.currencyCode'),
          multiplier: get(priceByCC[cur], 'priceInfo.multiplier'),
        });
        return acc;
      }, []);

      const zeroFilterArray = (arrayOfCC || []).filter((item: Money) => item.amount > 0);
      const minPrice = minBy(zeroFilterArray, 'amount');
      const maxPrice = maxBy(zeroFilterArray, 'amount');
      if (zeroFilterArray && zeroFilterArray.length === 0) {
        return 'RFQ';
      }

      if (minPrice && maxPrice) {
        if (minPrice.amount === maxPrice.amount) {
          return <Currency money={maxPrice} />;
        }
        return (
          <>
            {<Currency money={minPrice} />}-{<Currency money={maxPrice} />}
          </>
        );
      }
    } catch (err) {
      console.log(err) //eslint-disable-line
      return 'RFQ';
    }
  };

  const RenderExpandIcon = ({ expanded, expandable }: { expanded: boolean; expandable: boolean }) => {
    return expandable && (expanded ? <MinusSquareTwoTone /> : <PlusSquareTwoTone />);
  };

  const columns: ColumnsType<TableRecordType> = [
    {
      title: 'Doc',
      render: RenderDocAndMedia,
    },
    {
      title: 'Part #',
      dataIndex: 'partNumber',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      width: 200,
    },
    {
      title: 'Company Name',
      dataIndex: 'companyInfo',
      render: RenderCompanyName,
      filters: companyFilters,
      key: 'companyIds',
    },
    {
      title: 'Qty',
      dataIndex: 'totalQuantity',
    },
    {
      title: 'CC',
      dataIndex: 'conditionCodes',
      render: RenderCC,
      /*sorter: true,*/
    },
    // {
    //   title: 'Location',
    //   dataIndex: 'locations',
    //   render: RenderLocation,
    //   sorter: true,
    // },
    {
      title: 'Price',
      dataIndex: 'pricing',
      render: (pricing, record) => RenderPrice(pricing, record),
    },
    {
      title: 'Added',
      dataIndex: 'createdAt',
      render: FormattedDate,
    },
    {
      title: '',
      dataIndex: 'id',
      render: actionColumn,
    },
  ];

  return (
    <>
      <DataTable
        context="search"
        procedure={DTProcedurePublic}
        parameters={parameters}
        pagination={{ pageSize: 200 }}
        columns={columns}
        style={{ minHeight: '400px' }}
        onRow={(record) => {
          return {
            onClick: () => handleRowExpand(record),
          };
        }}
        expandable={{
          expandedRowRender: ExpandedRowRender,
          rowExpandable: (record) => rowExpandable(record),
          // expandRowByClick: true,
          expandedRowKeys,
          expandIcon: RenderExpandIcon,
        }}
        isMobileResponsive
        renderMobileRow={(item: TableRecordType) => (
          <div className={styles.mobileListCard} key={item.id}>
            <Row gutter={6} key={item.id} className={styles.rowContainer}>
              <Col span={6} style={{ padding: 10 }}>
                <img
                  alt="list-place-holder"
                  src={get(item, 'mediaImages[0].src') || imageIcon}
                  style={{ width: 40, height: 40 }}
                />
              </Col>
              <Col span={18}>
                <Row style={{ marginTop: 5 }}>
                  <div>
                    <span className={styles.serialNumber}>{get(item, 'partNumber')}</span> - {get(item, 'description')}
                  </div>
                </Row>
                <Row style={{ marginTop: 5 }}>
                  <div>{RenderDocAndMedia('', item)}</div>
                </Row>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={24}>
                <div>{RenderCompanyName(item.companyInfo)}</div>
              </Col>
            </Row>
            <Row gutter={12} style={{ marginBottom: 5 }}>
              <Col xs={4}>
                <LabelHeader label="Qty." />
                <div className={styles.qtyBox}>{get(item, 'totalQuantity') || 'N/A'}</div>
              </Col>
              <Col xs={10}>
                <LabelHeader label="CC" />
                <div>{RenderCC('', item)}</div>
              </Col>
              <Col xs={10}>
                <LabelHeader label="Price" />
                <div>{RenderPrice('', item)}</div>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={24} className={styles.actionBtnCol}>
                <div>{actionColumn('', item)}</div>
              </Col>
            </Row>
            <Divider />
          </div>
        )}
      />
      <APNModal id={MEDIA_IMAGES_ID} onCancel={() => closeModalById(MEDIA_IMAGES_ID)} title="Images" footer={null}>
        <div>
          {get(selectedRow, 'mediaImages', []).map((item: Media) => (
            <img
              src={item.src}
              key={item.id}
              alt="asd"
              style={{ width: 120, height: 120 }}
              className={styles.imageBlock}
            />
          ))}
        </div>
      </APNModal>
      <APNModal id={DOC_MEDIA_ID} onCancel={() => closeModalById(DOC_MEDIA_ID)} title="Documents" footer={null}>
        <div style={{ border: '1px solid #FCFCFC' }}>
          <APNTable
            dataSource={get(selectedRow, 'mediaDocuments', [])}
            pagination={false}
            rowKey="id"
            columns={[
              {
                title: 'Name',
                dataIndex: 'fileName',
                render: RenderDocName,
              },
            ]}
          />
        </div>
      </APNModal>
      <APNDrawer
        id={VIEW_STOCK_DRAWER_ID}
        placement="bottom"
        onClose={() => closeDrawerById(VIEW_STOCK_DRAWER_ID)}
        height={500}
        bodyStyle={{ padding: 10, overflowY: 'scroll' }}
        title={
          <Row gutter={6} className={styles.titleRowContainer}>
            <Col span={6} style={{ paddingLeft: 10 }}>
              <img
                alt="list-place-holder"
                src={get(selectedRow, 'mediaImages[0].src') || imageIcon}
                style={{ width: 60, height: 60 }}
              />
            </Col>
            <Col span={18}>
              <div className={styles.titleColSerialNumber}>
                <span>{get(selectedRow, 'partNumber')}</span>
                <br />
                <span style={{ color: '#000000' }}> {get(selectedRow, 'description')}</span>
              </div>
            </Col>
          </Row>
        }
      >
        <div className={styles.viewStockList}>{RenderViewStockList(selectedRow)}</div>
      </APNDrawer>
    </>
  );
};

export default PartListDT;
