import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { IRoute } from './common/types/Types';
import AuthenticatedRouteWrapper from './common/RouteWrappers/AuthenticatedRouteWrapper';
import PublicRouteWrapper from './common/RouteWrappers/PublicRouteWrapper';
import AuthRouteWrapper from './common/RouteWrappers/AuthRouteWrapper';
import { DashboardRoute } from './views/dashboard/Routes';
import { SignInRoute } from './views/signin/Routes';
import { SearchRoute } from './views/search/Routes';
import { NotFoundRoute } from './views/not-found/Routes';
// import { SettingsRoute } from './views/settings/Routes';
import { CartRoute } from './views/cart/Routes';
import { HistoryRoute } from './views/history/Routes';
import { VerifyRoute } from './views/verify/Routes';
import { BuyersRoute } from './views/buyers/Routes';
import { SellersRoute } from './views/sellers/Routes';
import { StoreFrontRoute } from './views/storefront/Routes';

const Routes = () => {
  const PublicRoutes = [SearchRoute, CartRoute, HistoryRoute, VerifyRoute, BuyersRoute, SellersRoute, StoreFrontRoute];
  const AuthRoutes = [SignInRoute];
  const AuthenticatedRoute = [DashboardRoute, NotFoundRoute];

  return (
    <Switch>
      {PublicRoutes.map((route: IRoute, index: number) => (
        <PublicRouteWrapper key={`public-route-wrapper-${index}`} {...route} />
      ))}
      {AuthenticatedRoute.map((route: IRoute, index: number) => (
        <AuthenticatedRouteWrapper key={`public-route-wrapper-${index}`} {...route} />
      ))}
      {AuthRoutes.map((route: IRoute, index: number) => (
        <AuthRouteWrapper key={`auth-route-wrapper-${index}`} {...route} />
      ))}
      <Redirect to="/search" from="/" />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
