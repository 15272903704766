import React from 'react';
import { Button, Col, Row } from 'antd';

import HeroBanner from '../../components/Banners/HeroBanner';
import VideoCard from '../../components/VideoCard';
import { APNBenefits, Banner, BannerTextData, WhyWeAreDifferentBanner } from '../../components/Banners';
import styles from '../buyers/styles.module.scss';
import { apnCurrency, branding, quoteBackgroundBlue, rfqInbox, sellersHeroBg, syncDetails } from '../../assets';
import { Image } from '../../components/DummyHelpers/Image';

const isMobile = window.innerWidth < 768;

const Sellers = () => {
  return (
    <>
      <HeroBanner backgroundImage={sellersHeroBg}>
        <Row>
          <Col xs={24} sm={12}>
            <h1 style={{ fontSize: 36, marginBottom: 20, fontWeight: 300 }}>Sellers</h1>
            <BannerTextData
              header="Turbo-Charged Selling"
              subheader="AeroParts Now empowers you to sell more, faster with an integrated, easy to use platform."
              textTheme="dark"
            />
            <VideoCard youtubeId="Zkb5x-VyaAs" />
            <a href="https://aeropartsnow.com/get-started" target="_blank" rel="noreferrer">
              <Button
                type="primary"
                className={styles.scheduleDemoButton}
                size="large"
                style={{ marginTop: 20, borderRadius: 4 }}
              >
                SCHEDULE DEMO
              </Button>
            </a>
          </Col>
        </Row>
      </HeroBanner>
      <Banner backgroundColor="#3C9BDB">
        <Row className={styles.bannerStyle}>
          <Col sm={12} xs={24} className={styles.bannerColStyle}>
            <BannerTextData
              header="Easy Inventory Sync"
              subheader="APN Sync automatically integrates and synchronizes inventory from your existing Enterprise Resource Planning (ERP) system, spreadsheet, or other data source directly to connected parts marketplaces."
              textTheme="light"
            />
          </Col>
          <Image image={syncDetails} />
        </Row>
      </Banner>
      <Banner backgroundColor="#F0F2F5">
        <Row className={styles.bannerStyle}>
          {!isMobile && <Image image={rfqInbox} />}
          <Col sm={12} xs={24} className={styles.bannerColStyle}>
            <BannerTextData
              header="Intelligent RFQ Inbox"
              subheader="Using a combination of artificial intelligence and automation, APN RFQ gives you a fast, streamlined and more transparent approach to quoting."
              textTheme="dark"
            />
          </Col>
          {isMobile && <Image image={rfqInbox} />}
        </Row>
      </Banner>
      <Banner backgroundColor="#3C9BDB">
        <Row className={styles.bannerStyle}>
          <Col sm={12} xs={24} className={styles.bannerColStyle}>
            <BannerTextData
              header="Custom-Branded Storefront"
              subheader="APN Storefront allows you to set up a custom-branded eCommerce store with a live connection to your inventory."
              textTheme="light"
            />
          </Col>
          <Image image={branding} />
        </Row>
      </Banner>
      <Banner backgroundColor="#FFFFFF">
        <Row className={styles.bannerStyle}>
          {!isMobile && <Image image={apnCurrency} />}
          <Col sm={12} xs={24} className={styles.bannerColStyle}>
            <BannerTextData
              header="Multi-Currency Enabled"
              subheader="Do business in the currency that best suits your needs—dollars, pounds, euros, pesos, yen."
              textTheme="dark"
            />
          </Col>
          {isMobile && <Image image={apnCurrency} />}
        </Row>
      </Banner>
      <WhyWeAreDifferentBanner
        backgroundImage={quoteBackgroundBlue}
        header="WHY WE’RE DIFFERENT"
        subheader="APN empowers you to accelerate your sales cycle and promote your brand to the world."
        textTheme="dark"
        button={{
          label: 'SCHEDULE DEMO',
          redirectionLink: 'https://aeropartsnow.com/get-started',
          type: 'primary',
        }}
      />
      <APNBenefits />
    </>
  );
};

export default Sellers;
