import {
  DATA_TABLE_LOADING,
  DATA_TABLE_LOADING_COMPLETE,
  FETCH_DATA_COMPLETE,
  INIT_DATA_TABLE,
  UPDATE_FILTER_PARAMS,
} from './Actions';
import { DataTableState } from './Types';
import { ReduxAction } from '../../common/types/Types';

// TODO need to replace the any with appropriate type
export default (state: DataTableState = {}, action: ReduxAction): DataTableState => {
  const { type, payload } = action;
  switch (type) {
    case INIT_DATA_TABLE: {
      const { context, ...rest } = payload;
      return {
        ...(state || {}),
        [context]: {
          ...rest,
        },
      };
    }

    case DATA_TABLE_LOADING: {
      const { context } = payload;
      return {
        ...state,
        [context]: {
          ...state[context],
          isLoading: true,
        },
      };
    }
    case DATA_TABLE_LOADING_COMPLETE: {
      const { context } = payload;
      return {
        ...state,
        [context]: {
          ...state[context],
          isLoading: false,
        },
      };
    }
    case FETCH_DATA_COMPLETE: {
      const { context, results } = payload;
      return {
        ...state,
        [context]: {
          ...state[context],
          data: results,
          isLoading: false,
        },
      };
    }
    case UPDATE_FILTER_PARAMS: {
      const { context, parameters } = payload;
      return {
        ...state,
        [context]: {
          ...state[context],
          parameters: {
            ...parameters,
          },
        },
      };
    }
    default:
      return state;
  }
};
