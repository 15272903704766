import { CreateUserParams, LoginParams, User } from './Types';

export const USER_LOGIN = 'user/USER-LOGIN';
export const USER_INFO_COMPLETE = 'user/USER-INFO-COMPLETE';
export const USER_LOGIN_SET_LOADING = 'user/USER-LOGIN-SET_LOADING';
export const USER_LOGIN_COMPLETE = 'user/USER-LOGIN-COMPLETE';
export const GET_USER = 'user/GET-USER';
export const GET_USER_COMPLETE = 'user/GET-USER_COMPLETE';
export const LOG_OUT_USER = 'user/LOG-OUT-USER';
export const LOG_OUT_COMPLETE = 'user/LOG-OUT-COMPLETE';
export const USER_LOGIN_SET_LOADING_COMPLETE = 'user/USER-LOGIN-SET-LOADING-COMPLETE';
export const CREATE_USER = 'user/CREATE-USER';
export const CREATE_USER_COMPLETE = 'user/CREATE-USER-COMPLETE';

// TODO below should be in company redux module, it is here just to show PUBLIC api calls
// export const GET_USER_COMPANY = 'user/GET-USER-COMPANY';

export const loginUser = (payload: LoginParams) => {
  return {
    type: USER_LOGIN,
    payload,
  };
};

export const getUser = () => {
  return {
    type: GET_USER,
  };
};

export const createUser = (payload: CreateUserParams) => {
  return {
    type: CREATE_USER,
    payload,
  };
};

export const setAuthLoading = () => ({ type: USER_LOGIN_SET_LOADING });
export const setAuthLoadingComplete = () => ({ type: USER_LOGIN_SET_LOADING_COMPLETE });
export const loginComplete = (payload: User) => ({ type: USER_LOGIN_COMPLETE, payload });
export const getUserComplete = (payload: User) => ({ type: GET_USER_COMPLETE, payload });
export const logoutUser = () => ({ type: LOG_OUT_USER });
export const logoutComplete = () => ({ type: LOG_OUT_COMPLETE });
