import { GlobalState } from './Types';
import {
  SET_CART_LOADING,
  UPDATE_CART,
  SET_CART_LOADING_COMPLETE,
  OPEN_MODAL,
  CLOSE_ALL_MODALS,
  UPDATE_MODAL_LIST,
  CREATE_SRFQ_COMPLETE,
  UPDATE_NETWORK_INFO,
  OPEN_DRAWER,
  CLOSE_ALL_DRAWERS,
  UPDATE_DRAWER_LIST,
} from './Actions';
import { ReduxAction } from '../../common/types/Types';

const initialState: GlobalState = {
  cart: {
    items: [],
    isLoading: false,
  },
  modals: [],
  network: {
    companyList: [],
  },
  drawers: [],
  localization: {
    currencyCode: 'USD',
  },
};

export default (state = initialState, action: ReduxAction) => {
  const { type, payload } = action;
  switch (type) {
    case SET_CART_LOADING:
      return {
        ...state,
        cart: {
          ...state.cart,
          isLoading: true,
        },
      };
    case UPDATE_CART:
      return {
        ...state,
        cart: {
          ...state.cart,
          items: payload,
        },
      };
    case SET_CART_LOADING_COMPLETE:
      return {
        ...state,
        cart: {
          ...state.cart,
          isLoading: false,
        },
      };
    case OPEN_MODAL: {
      const openModals = state.modals;
      openModals.push(payload);
      return {
        ...state,
        modals: [...openModals],
      };
    }
    case UPDATE_MODAL_LIST:
      return {
        ...state,
        modals: payload,
      };
    case CLOSE_ALL_MODALS:
      return {
        ...state,
        modals: [],
      };
    case CREATE_SRFQ_COMPLETE:
      return {
        ...state,
        cart: {
          ...state.cart,
          isLoading: false,
          items: [],
        },
      };
    case UPDATE_NETWORK_INFO:
      return {
        ...state,
        network: payload,
      };
    case OPEN_DRAWER: {
      const openDrawers = state.drawers;
      openDrawers.push(payload);
      return {
        ...state,
        drawers: [...openDrawers],
      };
    }
    case UPDATE_DRAWER_LIST:
      return {
        ...state,
        drawers: payload,
      };
    case CLOSE_ALL_DRAWERS:
      return {
        ...state,
        drawers: [],
      };

    default:
      return state;
  }
};
