import { CognitoUser } from '@aws-amplify/auth';
import { Auth, Analytics } from 'aws-amplify';
import { get } from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';

import { ISagaAction } from '../Types';
import {
  CREATE_USER,
  GET_USER,
  getUserComplete,
  LOG_OUT_USER,
  loginComplete,
  logoutComplete,
  setAuthLoading,
  setAuthLoadingComplete,
  USER_LOGIN,
} from './Actions';
import { CreateUserParams, LoginParams, User } from './Types';
import BaseService from '../../api/BaseService';
import history from '../../common/utils/history';
import { closeModal } from '../Global/Actions';
import { AUTH_MODAL_ID } from '../../common/utils/constants';
import { getUserProfile } from '../../queries';

function* loginUser({ payload }: ISagaAction<LoginParams>) {
  yield put(setAuthLoading());
  try {
    const res: CognitoUser = yield call(() => {
      return Auth.signIn(payload).then((res) => res);
    });
    const user = get(res, 'attributes');
    yield put(loginComplete(user));
    yield put(closeModal(AUTH_MODAL_ID));
    notification.success({ message: 'Login successful!' });
  } catch (e) {
    notification.error({ message: 'Login failed!', description: get(e, 'message', 'Unexpected error') });
    yield put(setAuthLoadingComplete());
  }
}

function* createUserSaga({ payload }: ISagaAction<CreateUserParams>) {
  yield put(setAuthLoading());
  try {
    const { email, password, name } = payload;
    yield call(() => {
      return Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          name,
          'custom:domainName': process.env.REACT_APP_DOMAIN_URL,
          'custom:registrationType': 'APN_MARKETPLACE',
        },
      }).then((res) => res);
    });
    yield put(setAuthLoadingComplete());
    yield put(closeModal(AUTH_MODAL_ID));
    notification.success({
      message: 'Registration successful!',
      description: 'Please check your mail to complete the verification process',
    });
    Analytics.record({ name: 'registration', attributes: { name, email } });
  } catch (e) {
    notification.error({
      message: 'Failed to create a user.',
      description: get(e, 'message', 'Unexpected error, please try again later. '),
    });
    yield put(setAuthLoadingComplete());
  }
}

function* logOutUser() {
  yield call(() => {
    return Auth.signOut();
  });
  yield put(logoutComplete());
  history.push('/');
}

function* getUserSaga() {
  const data: User = yield call(() => BaseService.auth({ query: getUserProfile }));
  yield put(getUserComplete(get(data, 'user')));
}

export default function* authSagas() {
  yield takeLatest(USER_LOGIN, loginUser);
  yield takeLatest(GET_USER, getUserSaga);
  yield takeLatest(LOG_OUT_USER, logOutUser);
  yield takeLatest(CREATE_USER, createUserSaga);
}
