import { IRoute } from '../../common/types/Types';
import Verify from './Verify';
import StaticLayout from '../../layouts/StaticLayout';

export const VerifyRoute: IRoute = {
  path: '/verify/:username/:code',
  component: Verify,
  layout: StaticLayout,
  exact: true,
  title: 'Verify',
};
